import { Component, SimpleChanges } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreakPointService } from 'src/app/core/services/break-point.service';

export let browserRefresh = false;
@Component({
  selector: 'app-regesteration',
  templateUrl: './regesteration.component.html',
  styleUrls: ['./regesteration.component.scss']
})
export class RegesterationComponent {
  subscription: Subscription;
  constructor(public _BreakPointService: BreakPointService, private router: Router){
  this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
  window.onresize = () => {
    this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
    this._BreakPointService.breakPoint.next(this._BreakPointService.isMobile)
    this._BreakPointService.mobileSizeChecker()
  };
    if (!router.navigated){
      this.router.navigate(['register/account-type'])
    }
}
}
