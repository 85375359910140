<div class="container-fluid d-flex flex-column justify-content-between px-0" dir="rtl">
  <!-- navbar layout -->
  <nav
  *ngIf='!this.activeRouter.url.includes("/request/edit-request/")
&& !this.activeRouter.url.includes("/request/Add-new-request")
&& !this.activeRouter.url.includes("/register")
&& !this.activeRouter.url.includes("/login")
&& !this.activeRouter.url.includes("Occupy-me")
&& !this.activeRouter.url.includes("/ads/add-new-advrties")
&& !this.activeRouter.url.includes("/forget-password")'>
    <div class="bg-white border-B-radius-s20">
      <app-navbar></app-navbar>
    </div>
  </nav>
  <!-- main pages layout -->
  <main class=" bg-white-sup">
    <router-outlet></router-outlet>
  </main>
  <!-- footer -->
  <footer class="mt-auto m-0"
  *ngIf='!this.activeRouter.url.includes("/request/edit-request/")
&& !this.activeRouter.url.includes("/request/Add-new-request")
&& !this.activeRouter.url.includes("/register")
&& !this.activeRouter.url.includes("/login")
&& !this.activeRouter.url.includes("Occupy-me")
&& !this.activeRouter.url.includes("/ads/add-new-advrties")
&& !this.activeRouter.url.includes("/forget-password")
&& !this.activeRouter.url.includes("/chat")'>
    <app-footer></app-footer>
  </footer>

</div>

<app-loader-progress></app-loader-progress>

<ng-template #notificationSnackBarTemplate >
    <app-notification-snack-bar (onDismissClick)="_SnackBarService.dismissSnackbar"></app-notification-snack-bar>
</ng-template>
<app-chat></app-chat>
<app-popup-chat></app-popup-chat>
