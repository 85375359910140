import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appHideImageOnError]'
})
export class HideImageOnErrorDirective {
  constructor(private el: ElementRef) {}

  @HostListener('error')
  hideImage(): void {
    this.el.nativeElement.remove();
  }
}
