import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SnackBarService } from './core/services/snack-bar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLogin:boolean = false;
  isFirebaseLogin:boolean=false;
  constructor( public activeRouter: Router,public _SnackBarService: SnackBarService) {}
  ngOnInit(): void {
    
    this.isLogin= localStorage.getItem("user") ? true :false;
    this.isFirebaseLogin= JSON.parse(localStorage.getItem("firebase")) ? true :false

  }

}
