import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { BreakPointService } from 'src/app/core/services/break-point.service';
declare var $: any;

@Component({
  selector: 'app-account-type',
  templateUrl: './account-type.component.html',
  styleUrls: ['./account-type.component.scss']
})
export class AccountTypeComponent {
  roleId: any;
  constructor(private router: Router, private _AuthService: AuthService, public _BreakPointService:BreakPointService) { }
  ngOnInit(): void {
    this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
    window.onresize = () => {
      this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
      this._BreakPointService.breakPoint.next(this._BreakPointService.isMobile)
      this._BreakPointService.mobileSizeChecker()
    };
    $('#user_click').click(function () {
      $('#user_Id').click();
    });
    $('#freelancer_click').click(function () {
      $('#freelancer_Id').click();
    });
    document.getElementById("user_Id")!.onchange = (e: any) => {
    }
    document.getElementById("freelancer_Id")!.onchange = (e: any) => {
    }

  }
  ngSubmit(data: any) { }
  accountRole() {
    this._AuthService.registerationForm = {
        role_id: this.roleId
      }
    setTimeout(() => {
      if (this._AuthService.registerationForm?.role_id == '2'){
        this.router.navigate(['/register/account-info'])
      }else{
        this.router.navigate(['/register/freelancer-type'])
      }
    }, 1000);
  }

}

