import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class FileTypeService {
  constructor(
    private _http: HttpClient
  ) {}

  getFileType(file: File): string {
    const mimeType = file.type;

    // Simple MIME type checks
    if (mimeType.startsWith('image/')) {
      return 'Image';
    } else if (mimeType === 'application/pdf') {
      return 'PDF';
    } else if (mimeType === 'application/vnd.ms-excel' || mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return 'Excel';
    } else if (mimeType === 'application/msword' || mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return 'Word Document';
    } else {
      return 'Unknown';
    }
  }

  upload(payload: any){
    return this._http.post(`${environment.apiUrl}/api/basic/media/store`,payload,{        
      reportProgress: true,
      observe: 'events',
    });
  }

  remove(id: number){
    return this._http.delete(`${environment.apiUrl}/api/basic/media?id${id}`);
  }

  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }
}
