import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  videos: any[] = []
  images: any[] = []
  file: any[] = []
  imageName: any[] = []
  FormulaOfFiles: any =
    [
      'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'txt',
      '3gp', 'wmv', 'mov', 'mp4', 'm4v', 'm4a', 'mp3', 'wav', 'mpeg', 'mpg4',
      'bmp', 'gif', 'jpg', 'jpeg', 'png', 'tif', 'tiff'
    ]
  imageNameCode!: Observable<any>
  constructor() { }
  saveImage(event: any) {
    if (this.FormulaOfFiles.includes(event.target.files[0].name.split(".").splice(-1)[0])) {
    this.imageName.push(event.target.files[0].name)
    const target = event.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    this.imagesConvertToBase64(file)
    setTimeout(function () {
      let res = event.target.files[0].name.split(".").splice(-1);
      if (res == 'pdf') {
        document.getElementById(event.target.files[0].name)?.classList.add('ic-request-PDF')
      } else if (res == 'xls') {
        document.getElementById(event.target.files[0].name)?.classList.add('ic-request-XLS')
      } else if (res == 'docx') {
        document.getElementById(event.target.files[0].name)?.classList.add('ic-request-DOC')
      } else if (res == 'png' || res == 'svg') {
        document.getElementById(event.target.files[0].name)?.classList.add('ic-request-image')
      } else if (res == 'mov') {
        document.getElementById(event.target.files[0].name)?.classList.add('ic-request-video')
      }
    }, 200)
  }
  }
  imagesConvertToBase64(file: File) {
    const observable = new Observable((subscriber: Subscriber<any>) => {
      this.readFileImage(file, subscriber)
    })
    observable.subscribe((d) => {
      if (file.type.split("/")[0] == 'image') {
        this.imageNameCode = d
        let img = (d.split(",")[1])
        let imgType = file.type.split("/")[1]
        let array = { file: img, type: imgType }
        this.images.push(array)
      } else if (file.type.split("/")[0] == 'video') {
        this.imageNameCode = d
        let videos = (d.split(",")[1])
        let videosType = file.type.split("/")[1]
        let array = { file: videos, type: videosType }
        this.videos.push(array)
      } else if (file.type.split("/")[0] == 'application') {
        this.imageNameCode = d
        let files = (d.split(",")[1])
        let fileType = file.type.split("/")[1]
        let array = { file: files, type: fileType }
        this.file.push(array)
      }
    })
  }
  readFileImage(file: File, subscriber: Subscriber<any>) {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      subscriber.next(fileReader.result)
      subscriber.complete()
    }
    fileReader.onerror = () => {
      subscriber.error(fileReader.error)
      subscriber.complete()
    }
  }
  removeImag(index: any) {
    let category = this.imageName.indexOf(index)
    this.imageName.splice(category, 1)
    this.images.splice(category, 1)
    this.videos.splice(category, 1)
    this.file.splice(category, 1)
  }
}
