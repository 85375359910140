import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
// import { RouterModule } from '@angular/router';
// import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { FeaturesModule } from './features/features.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { MaskedTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';
// import { MatIconModule } from '@angular/material/icon';
// import { LottieModule } from 'ngx-lottie';
// import player from 'lottie-web';
import { AgmCoreModule } from '@agm/core';
// import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
// import { TreeViewModule, TabModule } from '@syncfusion/ej2-angular-navigations';
import { JwtInterceptor } from './core/helpers/interceptors/jwt.interceptor';
// import { MatSelectModule } from '@angular/material/select';
// import { MatTreeModule } from '@angular/material/tree';
// import { MatSnackBarModule } from '@angular/material/snack-bar'
// import { MatFormFieldModule } from "@angular/material/form-field";
// import { MatTooltipModule } from '@angular/material/tooltip';
// import { MatInputModule } from '@angular/material/input';
// import { MatAutocompleteModule } from '@angular/material/autocomplete';
// import { AngularFireModule } from '@angular/fire/compat';
// import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
// import { environment } from 'src/environments/environment';
// import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
// import { provideAuth, getAuth } from '@angular/fire/auth';
// import { provideStorage, getStorage } from '@angular/fire/storage';
// import { provideFirestore, getFirestore } from '@angular/fire/firestore';
// import { MatListModule } from '@angular/material/list';
// import { MatDividerModule } from '@angular/material/divider';
// import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
// import { AngularFireStorageModule } from '@angular/fire/compat/storage';
// import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
// import { LoaderInterceptor } from './core/helpers/interceptors/loader.interceptor';
// import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { MatDialogModule } from '@angular/material/dialog';
// import { MatButtonModule } from '@angular/material/button';
// import { NgImageSliderModule } from 'ng-image-slider';
// import { AutocompleteLibModule } from 'angular-ng-autocomplete';
// import { NgSelectModule } from "@ng-select/ng-select";
import { PopupChatModule } from './shared/components/context-menu/popup-chat.module';
import { environment } from 'src/environments/environment';

// export function playerFactory() {
//   return player;
// }


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // AgmSnazzyInfoWindowModule,
    // MatButtonModule,
    // NgImageSliderModule,
    // AutocompleteLibModule,
    // MatDialogModule,
    // MatSlideToggleModule,
    // MatSelectModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    // NgSelectModule,
    // MatFormFieldModule,
    // ReactiveFormsModule,
    // MatTreeModule,
    // TreeViewModule,
    // TabModule,
    // MaskedTextBoxAllModule,
    FeaturesModule,
    // MatIconModule,
    // RouterModule,
    // FormsModule,
    SharedModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    // MatDialogModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapKey,
      language: 'ar'
    }),
    // TranslateModule.forRoot({ 
    //   defaultLanguage: 'ar',
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: createTranslateLoader,
    //     deps: [HttpClient]
    //   }
    // }),
    // LottieModule.forRoot({ player: playerFactory }),
    // MatSnackBarModule,
    // MatTooltipModule,
    // MatInputModule,
    // MatAutocompleteModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireDatabaseModule,
    // AngularFirestoreModule,
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAuth(() => getAuth()),
    // provideStorage(() => getStorage()),
    // provideFirestore(() => getFirestore()),
    // MatListModule,
    // MatDividerModule,
    // AngularFireStorageModule,
    // AngularFireMessagingModule,
    PopupChatModule,
    // GoogleMapsModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, AsyncPipe, DatePipe,
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
// Language File
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/languages/', '.json')
}
