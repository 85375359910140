import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(public snackBar: MatSnackBar) { }

  openWarningSnackBar(warningSnackbar:any) {
    this.snackBar.openFromTemplate(warningSnackbar, {
      duration: 2000,
      data: {},
      verticalPosition: 'bottom',
      horizontalPosition: 'right'
    });
  }
  openErrorSnackBar(errorSnackbar:any) {
    this.snackBar.openFromTemplate(errorSnackbar, {
      duration: 2000,
      data: {},
      verticalPosition: 'bottom',
      horizontalPosition: 'right'
    });

  }
  openSuccessSnackBar(successSnackbar:any) {
    this.snackBar.openFromTemplate(successSnackbar, {
      duration: 2000,
      data: {},
      verticalPosition: 'bottom',
      horizontalPosition: 'right'
    });
  }
  openNotificationSnackBar(notificationSnackbar:any) {
    this.snackBar.openFromTemplate(notificationSnackbar, {
      duration: 2000,
      data: {},
      verticalPosition: 'top',
      horizontalPosition: 'right'
    });
  }
  public dismissSnackbar(): void {
    this.snackBar.dismiss();
  }
}
