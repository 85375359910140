import { HttpClient ,HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { FileTypeService } from './file.service';
@Injectable({
  providedIn: 'root'
})
export class RequestService {
  lat: any = ''
  lan: any = ''
  type_work: any = ''
  category: any = ''
  compnay: any = ''
  freelancer: any = ''
  progress: any[] = [] ;
  data: any[]= []

  constructor(private _http: HttpClient,
    private fileTypeService: FileTypeService
  ) { }
  getRequestDetails(requestId:any){
    return this._http.get(`${environment.apiUrl}/api/task/show/${requestId}`)
  }
  editRequest(id:any , data:any){
    return this._http.post(`${environment.apiUrl}/api/task/update/${id}` , data)
  }
  deletRequest(id:any ){
    return this._http.post(`${environment.apiUrl}/api/ad/deleteImage/${id}` , '')
  }
  addRequestToFavorite(id: any, data:any) {
    return this._http.post(`${environment.apiUrl}/api/favourite/${id}`, data)
  }
  createNewRequest(data:any){
    return this._http.post(`${environment.apiUrl}/api/task/store`, data)
  }
  createNewComment(data:any){
    return this._http.post(`${environment.apiUrl}/api/task/comment`, data)
  }
  stopReceivingOffers(id:any){
    return this._http.get(`${environment.apiUrl}/api/task/change_status_offer/${id}`)
  }
  DeleteOffers(id:any ){
    return this._http.delete(`${environment.apiUrl}/api/task/delete/${id}`)
  }
  // get data for tlbat from api
  getData():Observable<any> {
    return this._http.post(`${environment.apiUrl}/api/task/list?perPage=20&pagination=true&search=true`, '')
  }
  PaginationOfData(numberOfPag: number) {
    return this._http.get(`${environment.apiUrl}/api/task/list?perPage=20&pagination=true&page=${numberOfPag}`)
  }

  // search data for tlbat from api
  searchData() {
    if(this.category == undefined){
      this.category = ''
    }
    return this._http.post(`${environment.apiUrl}/api/task/list?perPage=20&category=${this.category}&lat=${this.lat}&lan=${this.lan}&type_work=${this.type_work}&compnay=${this.compnay}&freelancer=${this.freelancer}&pagination=true`, '')
  }
  saveSearchData(model:any) {
    return this._http.get(`${environment.apiUrl}/api/basic/log/getSaveSearch?model=${model}`)
  }
  // delete value of search
  searchDelete(id:any) {
    return this._http.get(`${environment.apiUrl}/api/basic/log/deleteSaveSearch?model=task&id=${id}`)
  }
  // search data for tlbat by name
  searchByName(value:any) {
    return this._http.get(`${environment.apiUrl}/api/task/searchName?searchName=${value}`)
  }
  finalSearch(value:any){
    return this._http.post(`${environment.apiUrl}/api/task/list?pagination=true&search=true&search_value=${value}`, '')
  }
  // upload photo with progress bar
  uplodFile(file:any , i:number){
    this._http.post(`${environment.apiUrl}/api/basic/media/store`, file , {
      reportProgress: true,
      observe: "events"
    }).pipe(
      //  progress bar
      map((event: any) => {
        if (event.type == HttpEventType.UploadProgress) {
          this.progress[i].progres = Math.round((100 / event.total) * event.loaded);
        } else if (event.type == HttpEventType.Response) {
          // this.progress = null;
        }
      }),
      catchError((err: any) => {
        this.progress[i].progres = null;
        alert(err.message);
        return throwError(err.message);
      })
    )
    .toPromise();
  }
}
