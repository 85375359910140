import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { BreakPointService } from 'src/app/core/services/break-point.service';
import { ImageService } from 'src/app/core/services/image.service';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent {
  remainingText: any
  @ViewChild('fileInput') el: ElementRef;
  imageUrl: any = "../../../../../../assets/Icons/chat/user-placeholder.svg";
  imageSelected!: boolean; 
  editFile: boolean = true;
  removeUpload: boolean = false;
  constructor(public _AuthService: AuthService, private cd: ChangeDetectorRef, private router: Router, private _ImageService: ImageService
    , public _BreakPointService: BreakPointService) {
    this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
    window.onresize = () => {
      this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
      this._BreakPointService.breakPoint.next(this._BreakPointService.isMobile)
      this._BreakPointService.mobileSizeChecker()
    }; }
  uploadFile(event: any) {
    this._ImageService.saveImage(event)
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.imageSelected = true;
        this.editFile = false;
        this.removeUpload = true;
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  myChange(val: any) {
    this.remainingText = val.length + '/5000';
  }
  onSubmit(formVlaue: any) {
    this._AuthService.registerationForm = {
      ...this._AuthService.registerationForm,
      avatar: this._ImageService.images,
      job_name: formVlaue.job_name,
      info: formVlaue.info,
      commercial_number: formVlaue.commercial_number,
    }
    this._AuthService.displayProggressBar = true;
    setTimeout(() => {
      this.router.navigate(['/register/categories']);
      this._AuthService.displayProggressBar = false;
    }, 1000);
  }
}
