import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimateDirective } from './animate.directive';
import { CountUpDirective } from './count-up.directive';
import { DebounceInputDirective } from './debounce-input.directive';
import { HideImageOnErrorDirective } from './hide-image-on-error.directive';



@NgModule({
  declarations: [
    // AnimateDirective,
    // CountUpDirective
    DebounceInputDirective,
    HideImageOnErrorDirective
  ],
  imports: [
    CommonModule,
  ],
  exports:[
    // AnimateDirective,
    // CountUpDirective
    DebounceInputDirective,
    HideImageOnErrorDirective
  ]
})
export class DirectivesModule { }
