import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { BreakPointService } from 'src/app/core/services/break-point.service';
import { ImageService } from 'src/app/core/services/image.service';

@Component({
  selector: 'app-freelancer-info',
  templateUrl: './freelancer-info.component.html',
  styleUrls: ['./freelancer-info.component.scss']
})
export class FreelancerInfoComponent {
  remainingText: any
  imageUpload:boolean = true
  @ViewChild('fileInput') el: ElementRef;
  imageUrl: any = "../../../../../../assets/Icons/chat/user-placeholder.svg";
  editFile: boolean = true;
  removeUpload: boolean = false;
  constructor(public _AuthService: AuthService, private cd: ChangeDetectorRef, private router: Router, private _ImageService: ImageService
    , public _BreakPointService: BreakPointService) {
    this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
    window.onresize = () => {
      this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
      this._BreakPointService.breakPoint.next(this._BreakPointService.isMobile)
      this._BreakPointService.mobileSizeChecker()
    };
  }
  uploadFile(event: any) {
    this._ImageService.saveImage(event)
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        // this.registrationForm.patchValue({
        //   file: reader.result
        // });

        this.editFile = false;
        this.removeUpload = true;
      }
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
      this.imageUpload = false
    }
  }
  myChange(val: any) {
    this.remainingText = val.length + '/5000';
  }
  textrequired:boolean = true
  textarearequired(event:any){
    if(event == null) {
      this.textrequired = false
    }else {
      this.textrequired = true
    }
  }
  onSubmit(formVlaue: any) {
    this._AuthService.avater = {
      avatar: this._ImageService.images,
    }
    this._AuthService.registerationForm = {
      ...this._AuthService.registerationForm,
      job_name: formVlaue.job_name,
      info:  formVlaue.info,
      nationality_number: formVlaue.nationality_number,
    }
    this._AuthService.displayProggressBar = true;
    setTimeout(() => {
      this.router.navigate(['/register/categories']);
      this._AuthService.displayProggressBar = false;
    }, 1000);
  }
}
