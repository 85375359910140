// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://test-api.shogol.sa',
  googleMapKey: 'AIzaSyDx5CBk4DUW-nvCVnHAc-Jy0CIec0SKhR4',
  firebase: {
    // apiKey: "AIzaSyAMj1LZ0s4PfZlXcq-DdRWSyxdYHrX9Emg",
    // authDomain: "sho8l-96261.firebaseapp.com",
    // databaseURL: "https://sho8l-96261-default-rtdb.firebaseio.com",
    // projectId: "sho8l-96261",
    // storageBucket: "sho8l-96261.appspot.com",
    // messagingSenderId: "879215757540",
    // appId: "1:879215757540:web:8d79b23a2afd3b87e244f1",
    // measurementId: "G-V5294RZD1R"
    apiKey: "AIzaSyBm7DMzbSOj1ovM9v-yYrEQa39dw40Uz5A",
    authDomain: "prefab-isotope-411714.firebaseapp.com",
    databaseURL: "https://sho8l-96261-default-rtdb.firebaseio.com",
    projectId: "prefab-isotope-411714",
    storageBucket: "prefab-isotope-411714.appspot.com",
    messagingSenderId: "975269671124",
    appId: "1:975269671124:web:bfc473a550fbe50070cb41",
    measurementId: "G-TFN3Z35KFB"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
