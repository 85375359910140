import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/core/services/home.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

  constructor(public activeRouter:Router ){}
 firstMenuList =[
   { title: 'الإعلانات', router: '/ads/all-ads' }, { title: 'الطلبات', router: '/request' }, { title: 'المشتغلين', router:'/freelancer' }, { title:'تواصل معنا'}
 ]
 secondMenuList =[
   { title: 'عن منصة شغل' }, { title: 'الأسئلة الشائعة' }, { title: 'شروط الاستخدام' }, { title: 'اضافه سياسة الاستخدام والخصوصيه' }
 ]
 socialMediaIconsArray =[
   { icon: 'ic-Footer-Twitter-icon', link: 'https://twitter.com/i/flow/login?redirect_after_login=%2FShogol_sa' }, { icon: 'ic-Footer-Facebook-icon', link: 'https://web.facebook.com/shogol.sa?_rdc=11&_rdr'  }, { icon: 'ic-Footer-Instagram-icon', link: 'https://www.instagram.com/shogol_sa/'  }
 ]

}


