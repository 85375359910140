<!-- <ng-select
  class="font-Regular-s16 py-0 pb-2 px-4 bg-light-white text-support2-color border-f-radius-s15 border-1 Border-light-grey"
  [ngClass]="{ 'Border-f-red' : this.selected?.length > 10}" #tree="ngModel" [items]="this._tree.treeControl.dataNodes"
  bindLabel="name" appendTo="body" [multiple]="true" [(ngModel)]="selected" required maxlength="10" minlength="1"
  name="selected" placeholder="يجب ادخال مجالات ..." (change)="change()"> </ng-select>  -->
<input (focus)="showListOfCategories()" (keyup)="searchForCategories(searchTerm)" name="selected" type="text" [(ngModel)]="searchTerm"
  placeholder="يجب ادخال مجالات ..." id="category"
  class="inside d-flex justify-content-center align-items-center p-2 px-4 bg-light-white text-support2-color border-f-radius-s15 border-1 Border-light-grey w-100" 
  [ngClass]="{ 'Border-f-red' :allChoosesLemitError==true}">
  <div *ngIf=" allChoosesLemitError">
    <p *ngIf="allChoosesLemitError " class="m-0 CC-alert px-2">اختر عشر مجالات فقط</p>
  </div>
<div class="p-2 chooses-container inside">
  <span *ngFor="let choose of allChooses;index as x">
    {{choose.name}}
    <i (click)="removeCategory(x,choose.id)" class="inside fa-solid fa-circle-xmark"></i>
  </span>
</div>
<div style="max-height: 300px; overflow: auto;border-radius: 15px !important;"
  class="inside d-none py-2 ng-dropdown-panel ng-select-multiple font-Regular-s16 py-0 pb-2 px-4 bg-light-white text-support2-color border-f-radius-s15 border-1 Border-light-grey ng-star-inserted ng-select-bottom">
  <div class="inside ng-dropdown-panel-items scroll-host category first-level-menue position-relative overflow-auto"
    *ngFor="let cat of cateogries; index as i">
    <div>
      <div class="inside p-2 d-flex justify-content-between align-items-center" style="cursor: pointer;">
        <span class="inside d-inline-block"> 
          <input [id]="cat.id" class="inside {{i}}categoryChildParent" type="checkbox" name="scales" (change)="selectItem($event,cat)" [checked]="i < 10 ? this.routeCheck : false"/>
          <label class="inside d-inline-block mx-1">{{cat.name}}</label> 
        </span>
        <i id="{{i}}category" *ngIf="cat?.children?.length>0" class="inside fa-solid fa-chevron-left d-inline-block" (click)="openCategory($event,cat.children)"></i>
      </div>
      <ul id="{{i}}categoryChild" *ngIf="cat?.children?.length>0"  class="inside menuCategory second-level-menue position-relative">
        <li class="inside categorychildren p-1" *ngFor="let child of cat?.children;index as a" style="cursor: pointer;">
          <div class="inside d-flex justify-content-between align-items-center">
            <span class="inside d-inline-block">
              <input [id]="child?.id" type="checkbox" name="scales" (change)="selectItem($event,child)" class="inside {{a}}{{i}}subCategoryChildParent" [checked]="false"/>
              <label class="inside d-inline-block mx-1">{{child?.name}}</label> 
             </span> 
            <i id="{{a}}{{i}}subCategory" *ngIf="child?.children?.length>0" class="inside fa-solid fa-chevron-left mx-1 d-inline-block" (click)="openCategory($event,child.children)"></i>
          </div>
          <ul id="{{a}}{{i}}subCategoryChild" *ngIf="child?.children?.length>0" class="inside menuCategory third-level-menue position-relative">
            <li class="inside subCategorychildren p-1" *ngFor="let subChild of child.children; index as b" style="cursor: pointer;">
              <div class="inside d-flex justify-content-between align-items-center">
                <span class="inside d-inline-block">
                  <input [id]="subChild?.id"  type="checkbox" name="scales" (change)="selectItem($event,subChild)" class="inside {{b}}{{a}}{{i}}subSubCategoryParent" [checked]="false"/>
                  <label class="d-inline-block mx-1">{{subChild?.name}}</label> 
                 </span> 
                <i id="{{b}}{{a}}{{i}}subSubCategory" *ngIf="subChild?.children?.length>0" class="inside fa-solid fa-chevron-left mx-2 d-inline-block" (click)="openCategory($event,subChild?.children)"></i>
              </div>
              <ul id="{{b}}{{a}}{{i}}subSubCategoryChild" *ngIf="subChild?.children?.length>0" class="inside menuCategory fourth-level-menue position-relative">
                <li class="subCategorychildren p-1" *ngFor="let subSubChild of subChild.children ; index as c" style="cursor: pointer;">
                  <div class="d-flex justify-content-between align-items-center">
                    <span class="d-inline-block">
                      <input [id]="subSubChild?.id" type="checkbox" name="scales" (change)="selectItem($event,subSubChild)" class="inside {{c}}{{b}}{{i}}subSubSubCategoryParent" [checked]="false"/>
                      <label class="d-inline-block mx-1">{{subSubChild?.name}}</label> 
                    </span> 
                    <i id="{{c}}{{b}}{{i}}subSubSubCategory" *ngIf="subSubChild?.children?.length>0" class="inside fa-solid fa-chevron-left mx-3 d-inline-block" (click)="openCategory($event,subSubChild?.children)"></i>
                  </div>
                  <ul id="{{c}}{{b}}{{i}}subSubSubCategoryChild" *ngIf="subSubChild?.children?.length>0" class="inside menuCategory fivth-level-menue position-relative">
                    <li class="inside subCategorychildren p-1" *ngFor="let subSubSubChild of subSubChild?.children" style="cursor: pointer;">
                      <div class="inside d-flex justify-content-between align-items-center">
                        <span class="inside d-inline-block">
                          <input [id]="subSubSubChild?.id" type="checkbox" name="scales" (change)="selectItem($event,subSubSubChild)" class="inside" [checked]="false"/>
                            <label class="inside d-inline-block mx-1">{{subSubSubChild?.name}}</label> 
                        </span> 
                        <!-- <i id="{{i}}subSubSubCategory" *ngIf="subChild.children?.length>0" class="text-primary fa-solid fa-chevron-left mx-4 d-inline-block" (click)="openCategory($event)"></i> -->
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <p *ngIf="cateogries?.length ==0" class="inside d-flex justify-content-center align-items-end">لا توجد مجالات</p>

</div>
<p class="m-0 font-Regular-s14 text-dark-color mt-3 mb-2" *ngIf='this.activeRouter.url.includes("/request/edit-request/")
|| this.activeRouter.url.includes("/request/Add-new-request") '>
  اذا كان طلبك لا يحتاج لمهارات خاصة فبآمكانك توجية الطلب لمجال
  <span class="text-support1-color cursor-click" (click)="selectItemAny({id: 632, name: 'اي شغل'})">اي
    شغل</span>
</p>