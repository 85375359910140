<div class="bg-white border-f-radius-s20 py-5 d-flex flex-column align-items-center justify-content-center gap-3 h-100 ">
  <!-- welcome image -->
  <i class="ic-request-details-Undraw-Done Undraw-Done-Holder img-Background-Contain d-flex"></i>
  <!-- welcome text -->
  <p class="m-0 font-Bold-s32 text-dark-color">
    اهلا بك داخل منصة
    <span class="text-main-color">شغل</span>
  </p>
  <!-- welcome paragraph -->
  <p class="m-0 font-Regular-s14 text-light-black-color text-center lg:w-1/2 w-full pb-2">
    تهانينا على الانضمام إلى مجتمعنا النابض بالحياة من المستقلين والعملاء الموهوبين. يسعدنا وجودك على متن الطائرة،
    ونتطلع
    إلى دعمك في رحلتك المستقلة.
  </p>
  <!-- discover -->
  <button [routerLink]="['/ads/all-ads']" id="watch" aria-label="watch" class="mt-lg-4 mt-3 py-2 px-lg-5 px-3 text-white font-Bold-s16 bg-main border-f-radius-s12"
    style="width: 40%;">تصفح المنصة</button>
  <button [routerLink]="['/profile', this._AuthService?.id]" id="complete" aria-label="complete" class="py-2 px-lg-5 text-support2-color font-Bold-s16 bg-white border-f-radius-s12 mt-2" style="width: 40%;">
    إكمال الملف الشخصي
  </button>
</div>
