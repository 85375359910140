import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreakPointService {
  isMobile = false;
  breakPoint = new BehaviorSubject<any>([]);
  breakPoint$ = this.breakPoint.asObservable();
  // constructor() {
  //   this.mobileSizeChecker()
  // }
  mobileSizeChecker() {
    this.breakPoint$.subscribe(res => {
    })
  }
  getIsMobile(): boolean {
    const w = window.innerWidth;
    const breakpoint = 992;
    if (w < breakpoint) {
      return true;
    } else {
      return false;
    }
  }
}
