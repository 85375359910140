import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigurationOptions, NumberResult, OutputOptionsEnum } from 'intl-input-phone';
import { switchMap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { UsersService } from 'src/app/core/services/users.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
declare var $: any;
@Component({
  selector: 'app-forget-password-mobile',
  templateUrl: './forget-password-mobile.component.html',
  styleUrls: ['./forget-password-mobile.component.scss']
})
export class ForgetPasswordMobileComponent {
  @ViewChild('forgetPasswordForm', { static: false }) forgetPasswordForm!: NgForm;
  action: boolean = true;
  phoneNumForm: FormGroup;
  errMessage: string = ''
  configOption1: ConfigurationOptions;
  OutputValue2: NumberResult = new NumberResult();
  NumberModel: string = "+966";
  constructor(private formBuilder: FormBuilder, public activeRouter: Router, public _toasterService: ToasterService, public _AuthService: AuthService, public usersService: UsersService) {
    setTimeout(() => {
      let input:any = document.getElementsByName('InputPhone')[0]
      input.placeholder = '0xxxxxxxxxx'
    }, 100);
    this.phoneNumForm = this.formBuilder.group({
      sampleReactiveControl: new FormControl('', [Validators.required])
    });
    this.configOption1 = new ConfigurationOptions();
    this.configOption1.OutputFormat = OutputOptionsEnum.Number
  }
  get email() {
    return this.forgetPasswordForm.form.value;
  }
  ngSubmit(formValue: any) {
    // let value = formValue.email.CountryModel.CountryPhoneCode + formValue.email.Number
    // let numberNoSpace = value.replace(/\s/g, "");
    // let numberNoPlus = numberNoSpace.substring(1)
    const firstDigitStr = String(formValue.email.Number)[0];
    let num = formValue.email.Number
    let numberNoSpace = num.replace(/\s/g, "");
    let result = numberNoSpace.toString().substring(1);
    let mobileNumber = parseInt(result);
    let codeAndNumber = formValue.email.CountryModel.CountryPhoneCode + mobileNumber
    let codeAndNumberNoSpace = codeAndNumber.replace(/\s/g, "")
    let numberNoPlus = codeAndNumberNoSpace.substring(1)
    this._AuthService.loginForm = {
      mobile: numberNoPlus,
    }
    this._AuthService.displayProggressBar = true;
    this._AuthService.checkForgetEmail(this._AuthService.loginForm).subscribe((res: any) => {
        this._AuthService.displayProggressBar = false;
        this._toasterService.showSuccess('تم ارسال البيانات');
        setTimeout(() => {
          this._AuthService.verificationChecked = 1
        }, 1490);
    
  }, err => {
      this._AuthService.displayProggressBar = false;
      this._toasterService.showError(this.errMessage);
    })
  }
}
