import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Meta } from '../popup-chat.type';
import {
  addDoc,
  collection,
  collectionData,
  doc,
  documentId,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { getMessaging } from "firebase/messaging";
import { BehaviorSubject, concatMap, from, map, Observable, take, tap } from 'rxjs';
import { Chat, Message } from 'src/app/core/models/chats';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { UsersService } from 'src/app/core/services/users.service';
@Injectable({
  providedIn: 'root',
})
export class PopupChatService {
  meta$ = new Subject<Meta>();
  currentUser: any;
  currentUserID: any
  messaging = getMessaging();
  currentMessage = new BehaviorSubject(null);
  message: any;
  token: any;
  counter = new BehaviorSubject<number>(0);
  popUpChatId: any;
  constructor(
    private firestore: Firestore,
    private usersService: UsersService,
    private _angularFireMessaging: AngularFireMessaging,
    private _http: HttpClient
  ) {
    this.usersService.currentUserProfile$.subscribe((user) => {
      this.currentUser = user
    });
  }

  requestPermission() {
    this._angularFireMessaging.requestToken.subscribe((token: any) => {
      this.token = token
    }, (err) => {
    })
  }

  receiveMessage() {
    this._angularFireMessaging.messages.subscribe((payload: any) => {
      this.currentMessage.next(payload)
    })
  }

  get myChats$(): Observable<Chat[]> {
    const ref = collection(this.firestore, 'chats');
    return this.usersService.currentUserProfile$.pipe(
      concatMap((user) => {
        const myQuery = query(
          ref,
          where('userIds', 'array-contains', user?.uid)
        );
        collectionData(myQuery, { idField: 'id' }).subscribe((data) => {
          this.counter.next(data.filter((chat) => chat["haveUnseenMessages"] == true &&
            this.checkIfUserIsSender(this.currentUser?.uid, chat["lastMsgsenderId"])).length)
        })
        return collectionData(myQuery, { idField: 'id' }).pipe(
          map((chats: any) => this.addChatNameAndPic(user?.uid, chats))
        ) as Observable<Chat[]>;
      })
    );
  }

  async getChatsByChatName(chatName: string) {
    const ref = collection(this.firestore, 'users');
    const q = query(ref, where('displayName', '==', chatName));
    await onSnapshot(q, (snapshot) => {
      snapshot.forEach((user) => this.popUpChatId = user.data())
    })
  }

  checkIfUserIsSender(user: any, lastMsgsenderId: any) {
    if (user !== lastMsgsenderId) return true;
    else return false;
  }

  createChat(otherUser: any): Observable<string> {
    const ref = collection(this.firestore, 'chats');
    return this.usersService.currentUserProfile$.pipe(
      take(1),
      concatMap((user) =>
        addDoc(ref, {
          userIds: [user?.uid, otherUser?.uid],
          users: [
            {
              displayName: user?.displayName ?? '',
              photoURL: user?.photoURL ?? '',
            },
            {
              displayName: otherUser.displayName ?? '',
              photoURL: otherUser.photoURL ?? '',
            },
          ],
          unseenMessages: 0
        })
      ),
      map((ref) => ref.id)
    );
  }

  isExistingChat(otherUserId: string): Observable<string | null> {
    return this.myChats$.pipe(
      take(1),
      map((chats) => {
        for (let i = 0; i < chats.length; i++) {
          if (chats[i].userIds.includes(otherUserId)) {
            return chats[i].id;
          }
        }

        return null;
      })
    );
  }

  addChatMessage(chatId: string, message: string): Observable<any> {
    const ref = collection(this.firestore, 'chats', chatId, 'messages');
    const chatRef = doc(this.firestore, 'chats', chatId);
    const today = new Date();
    return this.usersService.currentUserProfile$.pipe(
      take(1),
      concatMap((user) => {
        this.currentUserID = user?.uid
        return addDoc(ref, {
          text: message,
          senderId: user?.uid,
          sentDate: today,
          seen: false,
          mid: Date.now().toString()
        })
      }
      ),
      concatMap((msg) => {
        let docRef = doc(this.firestore, 'chats', chatId, 'messages', msg.id);
        return updateDoc(docRef, {
          text: message,
          senderId: this.currentUserID,
          sentDate: today,
          seen: false,
          mid: msg.id
        })
      }
      ),
      concatMap(() =>
        updateDoc(chatRef, { lastMessage: message, lastMessageDate: today, haveUnseenMessages: true, lastMsgsenderId: this.currentUserID })
      )
    )
  }

  getChatMessages$(chatId: string): Observable<Message[]> {
    const ref = collection(this.firestore, 'chats', chatId, 'messages');
    const chatRef = doc(this.firestore, 'chats', chatId);
    const queryAll = query(ref, orderBy('sentDate', 'asc'));
    collectionData(queryAll).subscribe((chatMessages) => {
      if (chatMessages?.length > 0 && chatMessages[chatMessages?.length - 1]['senderId'] != this.currentUser.uid)
        updateDoc(chatRef, { haveUnseenMessages: false })
      this.getUnseenMessages(chatMessages, chatId)
    });
    return collectionData(queryAll) as Observable<Message[]>;
  }

  getUnseenMessages(chatMessages: any, chatId: any) {
    this.usersService.currentUserProfile$.subscribe((user) => {
      let recevedMessages = chatMessages.filter((msg: any) => user?.uid !== msg.senderId && msg.seen === false)
      if (recevedMessages.length > 0)
        this.updateMessageStatus(recevedMessages, chatId)
    })
  }

  updateMessageStatus(messages: any[], chatId: string) {
    for (let i = 0; i < messages.length; i++) {
      let docRef = doc(this.firestore, 'chats', chatId, 'messages', messages[i].mid);
      onSnapshot(docRef, (message) => updateDoc(docRef, {
        ...message.data, seen: true
      }))
    }
  }

  addChatNameAndPic(currentUserId: string | undefined, chats: Chat[]): Chat[] {
    chats.forEach((chat: Chat) => {
      const otherUserIndex =
        chat.userIds.indexOf(currentUserId ?? '') === 0 ? 1 : 0;
      const { displayName, photoURL } = chat.users[otherUserIndex];
      chat.chatName = displayName;
      chat.chatPic = photoURL;
    });

    return chats;
  }

  getUserDataFromChat(username: any) {
    return this._http.get(`${environment.apiUrl}/api/user/profile?username=${username}`)
  }
}

