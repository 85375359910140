<!-- Modal -->
<div class="modal fade" [id]="popupId" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body d-flex flex-column align-items-center gap-3 px-5 py-5">
                <!-- image -->
                <i class="ic-request-details-Undraw-Done Undraw-Done-Holder img-Background-Contain d-flex"></i>
                <!-- header -->
                <p class="m-0 font-Bold-s20 text-dark-black">
                    {{popupHeader}}
                </p>
                <!-- body -->
                <p class="m-0 font-Regular-s16 text-dark-black line-height-2 text-center">
                    {{popupDescription}}
                </p>
                <!-- action button -->
                <button id="modal" aria-label="modal"
                class="bg-main border-f-radius-s12 py-2 px-5 mt-3" data-bs-dismiss="modal" [routerLink]="router">
                    <span class="mx-4 text-white font-Bold-s16">
                        {{popupButton}}
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
