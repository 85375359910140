import { Component, OnInit, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopupChatService } from './services/popup-chat.service';
import { Meta } from './popup-chat.type';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/core/services/users.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { FormControl } from '@angular/forms';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Message } from 'src/app/core/models/chats';
import { Chat } from 'src/app/core/models/chats';
declare var $: any;

@UntilDestroy()
@Component({
  selector: 'app-popup-chat',
  templateUrl: './popup-chat.component.html',
  styleUrls: ['./popup-chat.component.scss'],
})
export class PopupChatComponent implements OnInit {
  @ViewChild('containerRef', { read: ViewContainerRef }) containerRef!: ViewContainerRef;
  isVisible = false;
  PopupChatPosition = { x: 0, y: 0 };
  meta: Meta = {};
  apiFile: any;
  finalFile: any;
  messageImage: any
  allMessages: any;
  fileDocument: any;
  mag: any
  userData: any;
  @ViewChild('endOfChat') endOfChat!: ElementRef;
  @ViewChild('sendInput', { static: true }) sendInput: ElementRef;
  messageControl = new FormControl('');
  chatListControl = new FormControl('');
  messages$: Observable<Message[]> | undefined;
  selectedChat$: Observable<Chat> | undefined;
  user$ = this.usersService.currentUserProfile$;
  selectedChat:any;
  myChats$ = this.chatsService.myChats$;
  otherUsers$ = combineLatest([this.usersService.allUsers$, this.user$]).pipe(
    map(([users, user]) => users.filter((u) => u.uid !== user?.uid))
  );

  constructor(
    private PopupChatService: PopupChatService,
    public _AuthService: AuthService,
    public activeRouter: Router,
    public usersService: UsersService,
    public _SnackBarService: SnackBarService,
    public chatsService: ChatService,
    private storage: AngularFireStorage,
  ) { }

  ngOnInit(): void { 
    this.subscribeToMeta();
  }

  subscribeToMeta() {
    this.PopupChatService.meta$.pipe(untilDestroyed(this)).subscribe({
      next: (content) => {
        if (!content.opened) {
          this.isVisible = false;
          return;
        }
          this.chatsService.isExistingChat(content.data["uid"]).subscribe((data)=>{
            if(data){
              let x=combineLatest([
                [...data],
                this.myChats$,
              ]).pipe(map(([value, chats]) => chats.find((c) => c.id === data))).subscribe((chat)=>this.selectedChat=chat)
              this.messages$ = this.chatsService.getChatMessages$(data)
              this.messages$?.subscribe((obj) => {
                  this.viewOldMessage(obj)
                })
              this.openSlectedChatPopUp()
            }
            else this.createChat(content.data);     
          })
          this.meta = content;
          this.isVisible = true;
      },
    });
  }

  createChat(user:any) {
    this.chatsService.isExistingChat(user.uid)
      .pipe(
        switchMap((chatId) => {
          if (!chatId) {
            return this.chatsService.createChat(user);
          } else {
            return of(chatId);
          }
        })
      )
      .subscribe((chatId) => {
        combineLatest([
          [...chatId],
          this.myChats$,
        ]).pipe(map(([value, chats]) => chats.find((c) => c.id === chatId))).subscribe((chat)=>this.selectedChat=chat)
        this.chatListControl?.setValue(chatId);
        this.openSlectedChatPopUp()

      });
  }

  freeUpPopupChat() {
    this.allMessages=null;
    this.selectedChat=null;
    this.chatListControl=null
    this.meta=null
    this.isVisible = false;
    this.PopupChatService.meta$.pipe(untilDestroyed(this))
  }

  viewOldMessage(messages: any) {
    this.chatListControl?.valueChanges.pipe(
      map((value) => value[0]),
    )
    this.allMessages = messages
    this.scrollToBottom();
    this.openSlectedChatPopUp()
  }

  sendMessage() {
    if (this.messageImage) {
      const filePath = `images/${Date.now()}_${this.messageImage.name}`;
      const task = this.storage.upload(filePath, this.messageImage);
      task
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((image) => {
          const selectedChatId = this.chatListControl?.value;
          
          if (image && selectedChatId||this.selectedChat.id) {
            this.chatsService
              .addChatMessage(this.selectedChat.id?this.selectedChat.id:selectedChatId, image)
              .subscribe(() => {
                this.scrollToBottom();
              });
            this.messageControl.setValue('');
          }
          this.messageControl.setValue('');

        })
        .catch((error) => {
          // console.error('Error uploading image: ', error);
        });
    } else if (this.fileDocument) {
      const filePath = `files/${Date.now()}_${this.fileDocument.name}`;
      const task = this.storage.upload(filePath, this.fileDocument);
      task.then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
          const selectedChatId = this.chatListControl?.value;
          if (url && 99 || this.selectedChat.id) {
            this.chatsService
              .addChatMessage(this.selectedChat.id?this.selectedChat.id:selectedChatId, url)
              .subscribe(() => {
                this.scrollToBottom();
              });
            this.messageControl.setValue('');
          }
          this.messageControl.setValue('');
        })
        .catch((error) => {
          // console.error('Error uploading file: ', error);
        });
    } else {
      const message = this.messageControl.value;
      const selectedChatId = this.chatListControl?.value;
        this.chatsService
          .addChatMessage(this.selectedChat.id?this.selectedChat.id:selectedChatId,message)
          .subscribe(() => {
            this.messages$ = this.chatsService.getChatMessages$(this.selectedChat.id?this.selectedChat.id:this.chatListControl?.value)
            this.messages$?.subscribe((obj) => {
              this.viewOldMessage(obj)
            })
            this.scrollToBottom();
          });
        this.messageControl.setValue('');
    }
    this.messageControl.setValue('');
    this.messageImage = ''
    this.fileDocument = ''
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.endOfChat) {
        this.endOfChat.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }

  close() {
    this.freeUpPopupChat()
    document.getElementById('chat')?.classList.remove('d-block')
    document.getElementById('chat')?.classList.add('d-none')
  }

  open_file() {
    document.getElementById('input_file').click();
  }

  fileChange(e: any) {
    let filePath = e.target.value
    this.apiFile = e.target.files[0]
    let file = filePath.split('\\')
    this.finalFile = file[file.length - 1]
    // send this
    let path = filePath.split(".").splice(-1)
    this.messageImage = ''
    this.fileDocument = ''
    if (path == 'txt' || path == 'pdf' || path == 'pptx' || path == 'ppt' || path == 'xls' || path == 'xlsx' || path == 'doc' || path == 'docx' || path == 'word') {
      this.fileDocument = e.target.files[0]
    } else {
      this.messageImage = e.target.files[0]
    }
    this.messageControl.setValue(this.finalFile)
  }

  togleChatTap() {
    $(".slected_chat_container").slideToggle("slow")
  }

  openSlectedChatPopUp() {
    if ($(".slected_chat_container").is(":visible")) {
      $(".arrow-direction").addClass("ic-caret-up-icon")
      $(".arrow-direction").removeClass("ic-caret-down-icon")
    } else {
      $("#chat").removeClass("d-none")
      $(".slected_chat_container").slideToggle("slow")
      $(".arrow-direction").addClass("ic-caret-down-icon")
      $(".arrow-direction").removeClass("ic-caret-up-icon")
    }
  }
  
  getChatImg(img:any){
    return typeof(img) !="object" && img? img : '../../../../assets/Icons/chat/user-placeholder.svg'
  }
}

