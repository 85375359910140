<div class="row gx-lg-5 h-full bg-white-sup">
  <!-- form section -->
  <div class="col-md-6 ps-md-3 h-100 bg-white-sup"
    [ngClass]="{'overflow-scroll': _BreakPointService.isMobile == true}">
    <div class="bg-white border-f-radius-s20 py-md-4 px-md-5 p-3">
      <div class="d-flex align-items-center gap-3 cursor-click mb-md-3 mb-3"
        routerLink="/register/account-type">
        <!-- arrow left -->
        <div
          class="bg-white-sup p-2 border-f-radius-s50 d-flex justify-content-center align-items-center cursor-click ">
          <i class="ic-arrow-small-left iC-S20 img-Background-Contain d-flex"></i>
        </div>
        <!-- back text -->
        <p class="m-0 font-Bold-s14 text-dark-black cursor-click">رجوع</p>
      </div>
      <!-- form header -->
      <div class="d-flex flex-column align-items-center gap-3">
        <p class="m-0 text-dark-color font-Bold-s20">معلومات الحساب</p>
        <p
          class="m-0 text-light-black-color font-Regular-s14 text-center line-height-15 px-lg-4">
          للتأكد من أن ملفك الشخصي كامل ويمثلك بدقة على منصتنا المستقلة، يرجى
          ملء نموذج معلومات المستخدم أدناه. ستساعدنا
          المعلومات
          التي تقدمها على توصيلك بالفرص ذات الصلة وخلق تجربة شخصية لك
        </p>
      </div>
      <!-- form -->
      <form #infoForm='ngForm' class="px-md-3" autocomplete="off"
        (ngSubmit)="onSubmit(infoForm.value)">
        <!-- phone number -->
        <div class="d-flex flex-column align-items-start gap-2 mt-md-3 mt-3">
          <p class="m-0 font-Bold-s14 text-dark-color">
            رقم الجوال
          </p>
          <!-- phone number input -->
          <intl-input-phone [(ngModel)]="number" name="mobile" required minlength="2"
            [ConfigurationOption]="configOption1" aria-label="mobile_input"
            #mobile="ngModel"
            [NumberTextValue]="NumberModel" dir="ltr"
            (OnCountryDrpdwnChange)="change($event)">
          </intl-input-phone>
          <p class="m-0 font-Bold-s12" [ngClass]="number?.Number[0] != 0 ? 'CC-alert' : 'text-success' " >
            رقم الجوال يجب ان يبدأ بصفر
            <!-- <p  class="m-0 text-success font-Bold-s12" *ngIf="mobileWarning">رقم الجوال يجب ان يبدأ بصفر -->
          </p>
        </div>
        <!-- name and fullname -->
        <div class="d-md-flex align-items-start mt-3 gap-4">
          <!-- User FullName  -->
          <div class="d-flex flex-column gap-2 w-100">
            <p class="m-0 font-Bold-s14 text-dark-color">
              الأسم بالكامل
            </p>
            <input type="text" name="fullName" autocomplete="none"
              aria-label="Full Name"
              class="form-control bg-white border-f-radius-s12 h-100 px-3 py-2 font-Regular-s14 text-support2-color"
              id="fullName" aria-describedby="nameHelp" ngModel
              #fullName="ngModel" required
              placeholder="الإسم الاول والاخير" minlength="8" maxlength="20">
            <div *ngIf="fullName.errors && (fullName.dirty || fullName.touched)">
              <p *ngIf="fullName.errors?.['minlength']" class="m-0 CC-alert">إسم
                الفرد يجب أن لا يقل عن 8 حروف ولا يحتوي علي ارقام حروف فقط</p>
              <p *ngIf="fullName.errors?.['maxlength']" class="m-0 CC-alert">إسم
                الفرد يجب أن لا يزيد عن 20 حرف ولا يحتوي علي ارقام حروف فقط
              </p>
            </div>
          </div>
          <!-- username input -->
          <div class="d-flex flex-column mt-md-0 mt-3 gap-2 w-100">
            <p class="m-0 font-Bold-s14 text-dark-color">
              إسم المستخدم
            </p>
            <input type="text" name="userName"
              (keydown.space)="$event.preventDefault()" autocomplete="none"
              aria-label="userName"  (keypress)="writingEngilshOnly($event)"
              class="form-control bg-white border-f-radius-s12 h-100 px-3 py-2 font-Regular-s14 text-support2-color"
              id="userName" aria-describedby="nameHelp" ngModel
              #username="ngModel" required placeholder="@username"
              minlength="3" maxlength="30">
            <div *ngIf="username.errors && (username.dirty || username.touched)">
              <p *ngIf="username.errors?.['minlength']" class="m-0 CC-alert">إسم
                المستخدم يجب أن لا يقل عن 3 حروف والارقام يجب ادخالها
                بالانجليزية</p>
              <p *ngIf="username.errors?.['maxlength']" class="m-0 CC-alert">إسم
                المستخدم يجب أن لا يزيد عن 30 حروف والارقام يجب ادخالها
                بالانجليزية</p>
            </div>
          </div>
        </div>
        <!-- email address -->
        <!-- Email input -->
        <div class="mt-3 d-flex flex-column align-items-start gap-2">
          <p class="m-0 font-Bold-s14 text-dark-color">
            البريد الإلكترونى
          </p>
          <input type="email" name="email" autocomplete="off" aria-label="email"
            class="form-control bg-white border-f-radius-s15 h-100 px-3 py-2 font-Regular-s14 text-support2-color"
            id="email" aria-describedby="emailHelp" ngModel #email="ngModel"
            required placeholder="admin@shogol-sa.com"
            pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
          <div *ngIf="email.errors && (email.dirty || email.touched)">
            <p *ngIf="email.errors?.['pattern']" class="m-0 CC-alert">البريد
              الإلكترونى غير صحيح</p>
          </div>
          <!-- <p class="bg-danger" id="emailmessage">{{this.emailError}}</p> -->
        </div>
        <!-- password input -->
        <div class="mt-3 d-flex flex-column align-items-start position-relative w-100 pb-2">
          <p class="m-0 font-Bold-s14 text-dark-color mb-2">
            كلمة المرور
          </p>
          <input [type]="this.showPassword ? 'text' : 'password'"
            name="password" aria-label="password"
            class="font-Regular-s16 d-flex position-relative justify-content-between px-3 py-2.5 align-items-center w-100 bg-white border-f-radius-s15 Border-light-grey"
            autocomplete="one-time-code" id="password"
            aria-describedby="passwordHelp" ngModel #password="ngModel" required
            placeholder="**********"
            minlength="8" maxlength="15">
          <i
            class="cursor-click left-4 lg:top-11 top-9 position-absolute {{passwordIcon}} img-Background-Contain iC-S16 d-flex"
            (click)="this.hidePassword()" [class.hide]="this.showPassword"></i>
          <div *ngIf="password.errors && (password.dirty || password.touched)">
            <p *ngIf="password.errors?.['minlength']" class="m-0 CC-alert"> كلمة
              المرور يجب أن لا يقل عن 8 حروف والارقام يجب ادخالها بالانجليزية</p>
            <p *ngIf="password.errors?.['maxlength']" class="m-0 CC-alert"> كلمة
              المرور يجب أن لا تزيد عن 25 حرف والارقام يجب ادخالها بالانجليزية</p>
          </div>
        </div>
        <!-- submit button -->
        <button type="submit" [disabled]="infoForm.invalid"
          id="informationFormBtn" id="submit" aria-label="submit"
          class="mb-md-4 my-3 mt-md-4 w-100 border-f-radius-s12 border-0 btn-holder py-md-3 py-2 px-md-5 px-3 bg-main text-white font-Bold-s16"
          style="width: 231px;">
          <span *ngIf="this._AuthService.displayProggressBar">
            <mat-spinner></mat-spinner>
          </span>
          <span *ngIf="!this._AuthService.displayProggressBar"
            class="px-md-0 px-3">
            التالي
          </span>
        </button>
        <!-- privacy and policy  -->
        <p class="m-0 text-center ">
          <span class="font-Regular-s14 text-support2-color"> بالاستمرار أنت
            توافق على</span>
          <span class="font-Bold-s14 cursor-click text-dark-color"> سياسة
            الخصوصية والاستخدام</span>
        </p>
      </form>
      <!-- horizontal line -->
      <div class="horizontal-line my-3"></div>
      <!-- login -->
      <p class="m-0 text-center mb-4">
        <span class="font-Regular-s14 text-support2-color">لديك حساب باالفعل
        </span>
        <span class="font-Bold-s14 cursor-click text-dark-color"
          routerLink="/login">تسجيل الدخول </span>
      </p>
    </div>
  </div>
  <!-- image section -->
  <div class="col-6 h-100 bg-white-sup"
    *ngIf="_BreakPointService.isMobile == false">
    <div
      class="d-flex flex-column align-items-center justify-content-center gap-3 h-100 pb-5">
      <!-- image -->
      <i class="img-account-info image-holder img-Background-Contain d-flex"></i>
      <!-- section header -->
      <p class="m-0 text-dark-color font-Bold-s32 text-center">
        اهلا بك داخل منصة
        <span class="text-main-color">شغل</span>
      </p>
      <p
        class="m-0 font-Regular-s14 text-light-black-color text-center mt-2 w-75">
        تهانينا على الانضمام إلى مجتمعنا النابض بالحياة من المستقلين والعملاء
        الموهوبين. يسعدنا وجودك على متن الطائرة،
        ونتطلع
        إلى دعمك في رحلتك المستقلة.
      </p>
    </div>
  </div>

</div>