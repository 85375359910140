<div class="container-fluid px-0 d-flex flex-column bg-white-sup position-relative" style="height: 100vh;">
    <nav class="container px-0 pt-3 pb-3 pe-4">
        <!-- navbar logo -->
        <button class="" id="logo" aria-label="logo">
            <img class="logo-holder cursor-click" src="../../../../assets/Icons/main-logo.svg" alt="Your Company logo"
                routerLink="/" loading="lazy" >
        </button>
    </nav>
    <div class="container bg-white-sup px-0 w-100 h-100">
        <div class="row m-0 gx-5 w-100 h-100">
            <!-- form section -->
            <div class="col-lg-6 ps-3 col-12 md:h-full h-3/6">
                <div class="bg-white md:h-3/4 border-f-radius-s20 p-md-5 p-3">
                    <!-- form header -->
                    <div class="d-flex flex-column align-items-center gap-4 mt-md-5 mt-3">
                        <!-- forgetPassword header -->
                        <p class="m-0 text-dark-color font-Bold-s20">تغيير كلمة المرور</p>
                        <!-- forgetPassword description -->
                        <p class="m-0 text-light-black-color font-Regular-s14 text-center line-height-15 px-4">
                            “أطلق إمكاناتك. احتضان الحرية. قم بتمكين رحلتك المستقلة"
                        </p>
                    </div>
                    <!-- form -->
                    <form class #forgetPasswordForm='ngForm' (ngSubmit)="ngSubmit(forgetPasswordForm.value)">
                        <!-- phone number -->
                        <div class="d-flex flex-column align-items-start gap-2 mt-4">
                            <p class="m-0 font-Bold-s14 text-dark-color">
                                رقم الجوال
                            </p>
                            <!-- phone number input -->
                            <intl-input-phone ngModel  name="email" required [ConfigurationOption]="configOption1"
                                aria-label="email_input" [NumberTextValue]="NumberModel" dir="ltr">
                            </intl-input-phone>
                        </div>
                        <button [disabled]="forgetPasswordForm.invalid" type="submit" id="submit" aria-label="submit"
                            class="mt-4 w-100 text-white bg-main border-f-radius-s12 py-2 font-Bold-s16 "
                            style="height:45px">
                            <!-- <span *ngIf="this._AuthService.displayProggressBar">
                                <mat-spinner></mat-spinner>
                            </span> -->
                            <span *ngIf="!this._AuthService.displayProggressBar">إرسال رمز تحقق</span>
                        </button>

                    </form>
                    <!-- forget password button -->
                    <!-- <button routerLink="/forget-password"
                        class="my-4 w-100 bg-white border-f-radius-s12 py-2 font-Bold-s14 gap-1 d-flex justify-content-center align-items-center">
                        <i class="ic-forget-password img-Background-Contain iC-S16 d-flex"></i>
                        <p class="m-0 text-dark-color">نسيت كلمة المرور؟</p>
                    </button> -->
                    <!-- new account text -->
                    <div class="w-100 ">
                        <p class="cursor-click font-Bold-s14 text-dark-color pt-4 text-center" routerLink="/login">تسجيل
                            دخول</p>

                        <p class="line position-relative m-0 text-support2-color font-Regular-s14 text-center ps-2">
                            لايوجد حساب لديك داخل منصة شغل</p>
                    </div>
                    <!-- register text-->
                    <div class="w-100 d-flex justify-content-center mt-3 mb-md-5 mb-3">
                        <button routerLink="/register" id="newACC" aria-label="newACC"
                            class="font-Bold-s14 px-5 py-2 bg-white btn-border text-main-color border-f-radius-s12 ">إنشاء
                            حساب
                            الآن</button>
                    </div>
                </div>
            </div>
            <!-- image section -->
            <div class="col-lg-6 h-100 col-12  d-lg-block d-none">
                <div class="d-flex flex-column align-items-center justify-content-center gap-3 h-100 pb-5">
                    <!-- image -->
                    <i class="ic-forget-password-graph image-holder img-Background-Contain d-flex"></i>
                </div>
            </div>
        </div>

    </div>
    <!-- footer -->
    <footer class="bg-dark mt-auto pt-3 pb-3 d-flex justify-content-center position-fixed bottom-0 start-0 end-0"
        style="z-index: 1;">
        <p class="m-0 font-Regular-s14 text-white-color text-center">
            Copyright ©2023 All rights reserved | Shogol Platform by lun startup studio
        </p>
    </footer>
</div>
<!-- user feedback -->
