<div class="container-fluid px-0 overflow-x-hidden">
  <!-- header section -->
  <section class="d-flex justify-content-center align-items-center px-lg-0 px-4 height-home">
    <div
      class="header-gradient position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-center align-items-center ">
      <!-- circle 1 -->
      <div class="circle-one position-absolute top-0 d-flex justify-content-center align-items-center">
        <!-- circle two -->
        <div class="circle-two d-flex justify-content-center align-items-center">
          <!-- circle three -->
          <div class="circle-three"></div>
        </div>
      </div>
      <!-- header-text -->
      <div class="d-flex flex-column align-items-center md:w-6/12 w-full px-lg-0 px-4" animation="fadeInUp" delay="0"
        duration="1500" triggerOnce="false">
        <p class="m-0 text-dark-blue font-Regular-s18">حان الوقت</p>
        <p class="mx-0 font-Bold-s40 text-dark-color w-75 text-center">انجز اشغالك</p>
        <!-- header -->
        <p class="mx-0 font-Bold-s40 text-dark-color w-75 text-center">
          اكتشف مهاراتك و استغل خبراتك داخل منصة
          <span class="text-main-color position-relative underline-image~">شــغــل</span>
        </p>
        <!-- paragraph -->
        <p
          class="m-0 text-support2-color font-Regular-s16 text-center line-height-2 mt-lg-2 md:w-8/12 w-full px-lg-0 px-2">
          ستتمكن من عرض مواهبك والاستفاده منها بحيث تكون مصدر دخل اضافي لك و ستتمكن من الوصول لمشتغلين ينجزون لك اي
          اشغال تريد
          انجازها سوى الاشغال التي عن بعد او الاشغال التي عن قرب وذلك بعد ان تكتشف سعر السوق للخدمة التي تريدها وتختار
          المشتغل
          الانسب لك والجاهز لخدمتك ...
        </p>
        <!-- buttons -->
        <div
          class="d-flex flex-md-row flex-column justify-content-center align-items-center gap-4 mt-md-5 mt-4 position-relative"
          style="z-index: 2;">
          <!-- earn money button-->
          <button (click)="freelancer()" aria-label="freelancer" id="freelancer"
            class="Border-f-purple border-f-radius-s12 bg-main text-white font-Bold-s16 py-2.5 px-5 position-relative text-nowrap">
            <span class="mx-4">كن مشتغل وابداء سيرتك الذكية</span>
            <i class="ic-dollar iC-S40 imCC-Background-Contain d-flex position-absolute"
              style="right: 10px;top:-20px;"></i>
          </button>
          <!-- request freelancer button -->
          <button [routerLink]="['/freelancer']" id="route" aria-label='route'
            class="border-f-radius-s12 bg-white text-dark-color Border-f-purple font-Bold-s16 py-2 px-5 text-nowrap">
            <span>ابحث عن مشتغلين</span>
          </button>
        </div>
        <!-- users -->
        <div class="d-flex position-relative mt-lg-5 mt-4">
          <!-- number of users -->
          <div class="p-1 border-f-radius-s12 bg-white margin-image position-relative z-index-7">
            <p
              class="m-0 d-flex justify-content-center align-items-center border-f-radius-s12 font-Bold-s14 iC-S44 text-white bg-main">
              500+</p>
          </div>
          <!-- list of users -->
          <div *ngFor="let user of usersArray"
            class="p-1 border-f-radius-s12 bg-white margin-image position-relative {{user.index}}">
            <i class="{{user.image}} iC-S44 imCC-Background-Contain d-flex"></i>
          </div>
        </div>
      </div>

    </div>

  </section>
  <!-- Advantages section -->
  <section class="bg-main-10 border-t-radius-s2 lg:py-12 py-9 mb-5 px-lg-0 px-3">
    <div class="container px-0 d-flex flex-column align-items-center gap-3">
      <p class="m-0 font-Bold-s24 text-dark-color mt-lg-4 mt-0 discover-text-underline~ position-relative">
        اكتشف ميزاتنا الرئيسية </p>
      <p
        class="m-0 text-support2-color font-Regular-s16 lg:w-3/4 w-full text-center line-height-2 pb-lg-3 mt-lg-0 mt-3">
        "افتح الإمكانات الكاملة لمهنتك المستقلة من خلال مجموعتنا الشاملة من الميزات. من مطابقة المشاريع الشخصية والعملاء
        الذين
        تم التحقق منهم إلى المدفوعات الآمنة وترتيبات العمل المرنة، تم تصميم منصتنا لرفع مستوى تجربتك المستقلة.
      </p>
      <!-- avatages cards -->
      <div class="row mt-lg-4 mt-0">
        <!-- card -->
        <div class="position-relative col-lg-4 col-md-6 col-12 mb-4" *ngFor="let card of advantagesArray"
          animation="fadeInUp" delay="0" durations="700" offset="50" triggerOnce="false">
          <div
            class="cursor-click div-hover position-relative d-flex flex-column justify-content-center align-items-center gap-2 pt-5 pb-4 border-f-radius-s12 z-index-5">
            <!-- icon -->
            <i class="{{card.icon}} iC-S32 img-Background-Cover d-flex"></i>
            <!-- advantage -->
            <p class="m-0 text-dark-color font-Bold-s16 mt-2">{{card.header}}</p>
            <!-- description -->
            <p class="m-0 text-support2-color font-Regular-s14 text-center mb-2 mx-5">
              {{card.desc}}
            </p>
          </div>
          <div class="shadow-div"></div>

        </div>
      </div>
    </div>
  </section>
  <!-- request description section -->
  <section class="container px-md-0 px-3 mt-4 pt-lg-2 pb-5">
    <div class="row border-f-radius-s20 px-0">
      <!-- section image -->
      <div class="col-md-6 col-12 bg-main border-r-radius-s20 px-0">
        <i class="img-sections-requests request-image-holder img-Background-Cover d-md-flex d-none"></i>
        <i class="img-sections-responsive request-image-holder img-Background-Cover d-md-none d-flex"></i>
      </div>
      <!-- section text -->
      <div class="col-md-6 col-12 border-l-radius-s20 bg-main">
        <div class="py-4 d-flex flex-column gap-3 mb-lg-5">
          <!-- header -->
          <p class="m-0 text-white font-Bold-s32">جميع اشغالك عن بعد او بالحضور في منصه واحدة</p>
          <!-- description -->
          <p class="m-0 text-white font-Regular-s16 ms-5 line-height-2 mb-lg-4">
            هل أنت مستعد لنقل حياتك المهنية المستقلة إلى آفاق جديدة؟ تقدم منصتنا عالما من الفرص المثيرة في انتظارك
            لاستكشافها. بصفتك
            مستقلا موهوبا، فأنت تمتلك مهارات فريدة مطلوبة في مختلف الصناعات. من خلال قسم "اكتشاف المزيد من الطلبات"،
            نأتي بهذه الفرص
            في متناول يدك.
          </p>
        </div>
        <!-- horizontal line -->
        <div class="horizontal-line bg-white mt-3 mb-4 ms-2"></div>
        <!-- features -->
        <div class="d-flex flex-md-row flex-column justify-content-between w-75">
          <div class="d-flex flex-column gap-3 mb-md-5 mb-3">
            <!-- list item -->
            <div class="d-flex align-items-center gap-2">
              <i class="ic-apps iC-S20 img-Background-Contain d-flex"></i>
              <p class="m-0 text-white font-Bold-s16">تصفح الفئات الشائعة</p>
            </div>
            <!-- list item -->
            <div class="d-flex align-items-center gap-2">
              <i class="ic-filters iC-S20 img-Background-Contain d-flex"></i>
              <p class="m-0 text-white font-Bold-s16">فلاتر ذكية</p>
            </div>
          </div>
          <div class="d-flex flex-column gap-3 mb-5">
            <!-- list item -->
            <div class="d-flex align-items-center gap-2">
              <i class="ic-user-check-white iC-S20 img-Background-Contain d-flex"></i>
              <p class="m-0 text-white font-Bold-s16">توصيات المشروع الشخصية</p>
            </div>
            <!-- list item -->
            <div class="d-flex align-items-center gap-2">
              <i class="ic-graduation-cap iC-S20 img-Background-Contain d-flex"></i>
              <p class="m-0 text-white font-Bold-s16">نجاحك هو أولويتنا</p>
            </div>
          </div>
        </div>
        <!-- requests button -->
        <button class="Border-f-white bg-white border-f-radius-s12 text-main-color font-Bold-s16 px-5 py-2 mb-4"
          [routerLink]="['/request']" aria-label="route" id="routeReq">
          <span class="mx-5">عرض جميع الطلبات</span>
        </button>
      </div>
    </div>

  </section>
  <!-- some freelancer work -->
  <section class="container px-md-0 px-4 d-flex flex-column align-items-center gap-3 pb-md-5 pb-4">
    <p class="m-0 font-Bold-s24 text-dark-color mt-lg-4 mt-0 discover-text-underline~ position-relative">
      بعض أشغال المنصة
    </p>
    <p class="m-0 text-support2-color font-Regular-s16 lg:w-3/4 w-full text-center mt-2">
      "افتح الإمكانات الكاملة لمهنتك المستقلة من خلال مجموعتنا الشاملة من الميزات. من مطابقة المشاريع الشخصية والعملاء
      الذين
      تم التحقق منهم إلى المدفوعات الآمنة وترتيبات العمل المرنة، تم تصميم منصتنا لرفع مستوى تجربتك المستقلة.
    </p>
    <div class="row d-flex align-items-center">
      <div class="col-lg-6 col-md-12 mt-4 ">
        <div class="bg-white rounded-4 p-4 ">
          <p class="  font-Bold-s24 text-main-color m-0">اشغال اي شغل</p>
          <p class="pt-3 font-Regular-s14 text-support2-color ">تمكن اي شخص لا يمتلك الشهادة او الخبره في مجال معين
            ولديه وقت وقدره على الشغل من التسجيل في مجال (أي شغل) واستقبال الطلبات التي لا تحتاج الى مهارات متخصصه
            واختيار ما يناسبه منها بسعر يحدده مسبقا</p>
          <div class="d-flex flex-md-row flex-column justify-content-between py-md-3 gap-md-0 gap-2">
            <p class="text-main-color font-Bold-s32 m-0">4635 <span class="font-Regular-s16  text-support2-color"> طلبات
                اي شغل</span></p>
            <p class="text-main-color font-Bold-s32 m-0">635 <span class="font-Regular-s16 text-support2-color">مشتغلون
                يملكون هذه المهارة</span></p>
          </div>
          <button routerLink="/request" [state]="anyJop" id="anyJob" aria-label="anyJop"
            class="d-flex justify-content-center font-Bold-s16 text-main-color py-2.5 px-20 Border-f-purple border-f-radius-s12 bg-white mt-md-5 mt-4 text-nowrap">
            <span>استكشف طلبات اي شغل</span>
          </button>
        </div>
      </div>
      <div class=" col-lg-6 col-md-12">
        <div class="row">
          <div class="col-lg-6 mt-4 " *ngFor="let work of freelancerWork" animation="fadeInUp" delay="0" duration="5000"
            triggerOnce="false">
            <div class="bg-white border-f-radius-s16 p-4 d-flex gap-3 w-100 align-items-center">
              <!-- circle div -->
              <div
                class="bg-light  p-2.5 border-f-radius-s12 d-flex justify-content-center align-content-center flex-shrink-0">
                <i class="d-flex iC-S24 img-Background-Contain {{work.icon}} "></i>
              </div>
              <div class="d-flex flex-column gap-2 overflow-hidden">
                <p class="m-0 text-dark-color font-Bold-s16 text-nowrap">{{work.name}}</p>
                <p class="m-0 text-dark-color font-Regular-s14 text-nowrap">{{work.number}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
  <!-- website statistics -->
  <section class="mt-4 mt-2 mb-5 py-5 border-f-radius-s16 statistics-bg" id="othdiv">
    <div class="container px-lg-0 px-3">
      <div class="row">
        <div class="col-lg-6 d-flex flex-column gap-3">
          <!-- logo -->
          <i class="ic-request-white-logo d-flex ic-S-logo mb-lg-4"></i>
          <!-- header -->
          <p class="m-0 font-Bold-s20 text-white ms-lg-5 mt-lg-2">
            تتحدث الأرقام بصوت أعلى من الكلمات. منصتنا مكرسة لتمكين المستقلين مثلك لتحقيق نجاح ملحوظ في عالم العمل عن
            بعد. دع
            الأرقام تتحدث:
          </p>
          <!-- desc -->
          <p class="m-0 font-Regular-s16 text-white ms-5 line-height-15">
            نحن لسنا مجرد منصة؛ نحن منصة انطلاق لحياتك المهنية. انضم إلى صفوف المستقلين الذين حولوا شغفهم إلى مهنة
            مزدهرة.
          </p>
        </div>
        <div class="col-lg-6 mt-lg-0 mt-3">
          <div class="row">
            <div class="col-lg-6">
              <!-- statistic -->
              <div class="d-flex flex-column">
                <p class="m-0 font-Bold-s32 text-white">
                  +<span [countUp]="count" [duration]="duration"></span>
                </p>
                <p class="m-0 text-white font-Bold-s16 mb-2">طلبات عملاء</p>
                <p class="m-0 text-white font-Regular-s14">
                  انضم إلى سوق نابض بالحياة حيث يتم نشر ملايين المشاريع من قبل العملاء من جميع أنحاء العالم. سواء كنت
                  محترفا محنكا أو بدأت
                  للتو، فهناك مشروع مصمم خصيصا لمهاراتك وخبراتك.
                </p>
              </div>
              <div class="d-flex flex-column mt-4">
                <p class="m-0 font-Bold-s32 text-white">
                  +<span [countUp]="requestsCount" [duration]="requestsduration"></span>
                </p>
                <p class="m-0 text-white font-Bold-s16 mb-2">إعلانات داخل المنصة</p>
                <p class="m-0 text-white font-Regular-s14">
                  حقق الدقة في استراتيجية التسويق الخاصة بك من خلال الإعلانات المستهدفة. قم بتخصيص إعلاناتك وفقا
                  للديموغرافيات أو الصناعات
                  أو تفضيلات المستخدم المحددة، مما يضمن وصول رسالتك إلى الجمهور المناسب.
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="d-flex flex-column">
                <p class="m-0 font-Bold-s32 text-white">
                  +<span [countUp]="freelancersCount" [duration]="freelancersduration"></span>
                </p>
                <p class="m-0 text-white font-Bold-s16 mb-2">مشتغلين باالمنصة</p>
                <p class="m-0 text-white font-Regular-s14">
                  يضم مجتمعنا شبكة واسعة من الخبراء المستقلين. مع [اسم موقع المستقل]، ستكون جزءا من مجموعة موهوبة من
                  الأفراد المعترف بهم
                  لمهاراتهم الاستثنائية وتفانيهم في التميز.
                </p>
              </div>
              <div class="d-flex flex-column mt-5">
                <p class="m-0 font-Bold-s32 text-white">
                  +<span [countUp]="fieldsCount" [duration]="fieldsduration"></span>
                </p>
                <p class="m-0 text-white font-Bold-s16 mb-2">فئات المهارات المتنوعة</p>
                <p class="m-0 text-white font-Regular-s14">
                  من المساعي الإبداعية إلى المشاريع التقنية، نلبي مجموعة متنوعة من فئات المهارات. مهما كان مكانك، لدينا
                  فرص تتناسب مع
                  مواهبك.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- request the service -->
  <section
    class="container px-0 bg-image border-f-radius-s16 mb-5 py-5 d-flex flex-column justify-content-lg-between align-items-start gap-2 px-4 position-relative">
    <div class="d-flex flex-column justify-content-start align-items-start gap-2">
      <!-- section-layer -->
      <div class="bg-layer position-absolute top-0 bottom-0 start-0 end-0 border-f-radius-s20 z-index-1"></div>
      <p class="m-0 text-dark-blue font-Bold-s24">لطالب الخدمه</p>
      <p class="m-0 font-Bold-s32 text-white lg:w-3/4 w-full">اكشف اسعار السوق للخدمة التي تريدها من خلال المشتغلين
        الجاهزين لخدمتك
      </p>
      <p class="m-0 text-white font-Regular-s16 w-75 mt-lg-0 mt-3">من خلال ارسالك لتفاصيل الخدمة التي تريدها ستتلقى عدد
        من عروض
        الاسعار ويتبين لك سعررالسوق للخدمه التي تبحث عنها وسنوفر
        عليك عنا البحث والتقصي للوصول لسعر يناسب ميزانيتك ومقدم خدمه تثق به وجاهز لخدمتك ..</p>
    </div>

    <button aria-label="route" id="routeReqAdd"
      class="d-flex flex-column gap-2 bg-main text-white border-f-radius-s20 p-4 position-relative z-index-2 mt-lg-0 mt-5"
      [routerLink]='["/request/Add-new-request"]'>
      <div class="d-flex align-items-center gap-2">
        <i class="ic-diploma-white d-flex iC-S32 img-Background-Contain"></i>
        <p class="m-0 text-white font-Bold-s28">اطلب عرض سعر الأن</p>
      </div>
      <div class="d-flex align-items-center gap-5">
        <p class="m-0 text-white font-Regular-s16 text-end">استقبل العديد من العروض حتي تتمكن من اتمام مهامك الأن</p>
        <i class="ic-arrow-right-white d-flex iC-S24 img-Background-Contain"></i>
      </div>
    </button>
  </section>
  <!-- contact-us -->
  <section class="container bg-white px-0 border-f-radius-s20 my-5 position-relative">
    <div class="row p-md-5 p-3">
      <div class="col-lg-6 col-12">
        <!-- form -->
        <form class="d-flex flex-column justify-content-between gap-3" #conatctForm='ngForm'
          (ngSubmit)="ngSubmit(conatctForm.value)">
          <!-- phone number -->
          <div class="d-flex flex-column align-items-start gap-2">
            <p class="m-0 font-Bold-s14 text-dark-color">
              رقم الجوال
            </p>
            <!-- phone number input -->
            <intl-input-phone ngModel name="mobile" required [ConfigurationOption]="configOption1"
              aria-label="mobile_input" [NumberTextValue]="NumberModel" dir="ltr">
            </intl-input-phone>
          </div>
          <div class="d-flex flex-md-row flex-column justify-content-start align-items-start gap-4">
            <!-- User FullName  -->
            <div class="d-flex flex-column align-items-start gap-2 w-100" style="flex:1">
              <p class="m-0 font-Bold-s14 text-dark-color">
                الأسم بالكامل
              </p>
              <input aria-label='fullname' type="text" name="fullname" autocomplete="none"
                class="form-control bg-white border-f-radius-s12 h-100 px-3 font-Regular-s14 text-support2-color "
                id="fullname" aria-describedby="nameHelp" ngModel #fullname="ngModel" placeholder="الأسم الأول والأخير"
                minlength="2" maxlength="50">
              <div *ngIf="fullname.errors && (fullname.dirty || fullname.touched)">
                <p *ngIf="fullname.errors?.['minlength']" class="m-0 CC-alert">إسم الفرد كامل يجب أن
                  لا يقل عن 2 حروف
                  والارقام يجب ادخالها بالانجليزية</p>
                <p *ngIf="fullname.errors?.['maxlength']" class="m-0 CC-alert">إسم الفرد كامل يجب أن
                  لا يزيد عن 50 حروف
                  والارقام يجب ادخالها بالانجليزية
                </p>
              </div>
            </div>
            <!-- Email input -->
            <div class="d-flex flex-column align-items-start gap-2 w-100 h-100" style="flex:1">
              <p class="m-0 font-Bold-s14 text-dark-color">
                البريد الإلكترونى
              </p>
              <input aria-label="email" type="email" name="email" autocomplete="off" ngModel #email="ngModel"
                class="form-control bg-white border-f-radius-s12 h-100 px-3 font-Regular-s14 text-support2-color w-100"
                id="email" aria-describedby="emailHelp" required placeholder="admin@shogol-sa.com"
                pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
              <div *ngIf="email.errors && (email.dirty || email.touched)">
                <p *ngIf="email.errors?.['pattern']" class="m-0 CC-alert">البريد الإلكترونى غير صحيح</p>
              </div>
            </div>
          </div>
          <!-- opinion -->
          <div class="d-flex flex-column gap-2 w-100">
            <p class="m-0 font-Bold-s14 text-dark-color">
              رأيك باالمنصة
            </p>
            <div class="w-100 relative">
              <textarea placeholder="اكتب رسالتك..."
                class="form-control Border-light-grey bg-white border-f-radius-s12 h-100 px-3 py-2 font-Regular-s14 text-support2-color"
                name="description" cols="30" rows="7" ngModel required #description="ngModel" id="description"
                minlength="2" maxlength="150" (ngModelChange)="myChange($event)"></textarea>
              <span
                class="position-absolute bottom-4 text-support2-color font-Regular-s16 left-4">{{remainingText}}</span>
            </div>
            <div *ngIf="description.errors && (description.dirty || description.touched)">
              <p *ngIf="description.errors?.['minlength']" class="m-0 CC-alert"> نبذة عني يجب أن لا يقل عن 2 حرف
                والارقام يجب
                ادخالها
                بالانجليزية
              </p>
              <p *ngIf="description.errors?.['maxlength']" class="m-0 CC-alert">
                نبذة عني يجب أن لا تزيد عن 150 حرف
              </p>
            </div>
          </div>
          <div class="w-100">
            <button type="submit" [disabled]="conatctForm.invalid" aria-label="submit" id="submit"
              class="w-100 d-flex align-items-center justify-content-center border-f-radius-s12 gap-2 text-white bg-main py-2.5 px-5">
              <i class="ic-paper-plane-white d-flex iC-S20 img-Background-Contain"></i>
              <p class="m-0 font-Bold-s16">إرسال</p>
            </button>
            <p class="m-0 CC-message-holder fCC-Light-sA mt-1" id="message">{{message}}</p>
            <p class="m-0 CC-err-message-holder fCC-Light-sA mt-1">{{errMessage}}</p>
          </div>
        </form>
      </div>
      <div class="col-lg-6 col-12 px-0 mt-lg-0 mt-5">
        <div class="d-flex flex-column justify-content-between px-md-5 px-3 h-100">
          <!-- contact-us text -->
          <div class="d-flex flex-column gap-3">
            <p class="m-0 text-dark-color font-Bold-s24">"تواصل معنا - نحن هنا للمساعدة!"</p>
            <p class="m-0 text-support2-color font-Regular-s16">نحن نقدر ملاحظاتك ونهدف إلى التحسين المستمر لمنصتنا
              لخدمتك بشكل أفضل. لا تتردد في مشاركة أفكارك أو اقتراحاتك. تواصل معنا
              اليوم واختبر المستوى الاستثنائي من الخدمة التي تميز [منصة شغل]"</p>
          </div>
          <!-- contact us -->
          <div class="d-flex flex-column gap-lg-4 gap-4 mt-lg-0 mt-3">
            <div class="d-flex gap-2" *ngFor="let contact of contactArray">
              <div class="border-f-radius-s6 bg-main-10 iC-S40 d-flex justify-content-center align-items-center">
                <i class="{{contact.icon}} iC-S20 d-flex img-Background-Contain"></i>
              </div>
              <!-- contacts -->
              <div class="d-flex flex-column gap-1">
                <p class="m-0 text-dark-color font-Bold-s14">{{contact.contactName}}</p>
                <p class="m-0 text-support2-color font-Regular-s14">{{contact.contactDetails}}</p>
              </div>
            </div>
          </div>
          <div class="position-absolute mail-dimensions">
            <ng-lottie [options]="options" width="145px" height="239px"></ng-lottie>
            <!-- <i class="ic-new-mail d-flex mail-dimensions img-Background-Contain position-absolute"></i> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
