<!-- Modal -->
<div class="modal fade" id="requestOffer" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 border-f-radius-s12">
            <!-- Modal content -->
            <div class="modal-body p-0">
                <div class="bg-main d-flex flex-column gap-1 px-4 pb-4 pt-5 mb-3 position-relative border-l-radius-s12">
                    <!-- Popup Header -->
                    <img class="hidden logo-holder lg:block mt-2" src="../../../../assets/Icons/main-logo.svg"
                        alt="Your Company logo" loading="lazy">
                    <p class="m-0 font-Bold-s22 text-white">قدم عرضك علي طلب العميل الآن...</p>
                    <p class="m-0 font-Regular-s14 text-white mb-1">انت علي بعد خطوة من كشف سعر السوق والوصول للمشتغلين
                        المستعدين
                        لخدمتك</p>
                    <img src="../.././../../assets/Icons/request-details/undraw-Done.svg"
                        class="img-dimention-holder position-absolute" loading="lazy" alt="undraw">
                </div>
                <form class="bg-white p-5 mt-4 border-b-radius-s12" #OfferRequestForm='ngForm'
                    (ngSubmit)="ngSubmit(OfferRequestForm.value)">
                    <div class="d-flex justify-content-between gap-4">
                        <!-- offer Vlaue -->
                        <div class="form-group d-flex flex-column gap-2 w-100">
                            <!-- inout name -->
                            <label for="offerValue" class="font-Bold-s16 text-dark-grey">قيمة العرض*</label>
                            <div class="input-group Border-light-grey bg-light-white border-f-radius-s12 w-100">
                                <!-- input -->
                                <input type="text" name="price" class="form-control border-0 p-0 bg-transparent pe-2"
                                    autocomplete="off" aria-label="offerValue" ngModel #price="ngModel" required
                                    aria-describedby="offerValue" (keypress)="this._AuthService.numberOnly($event)">
                                <!-- value -->
                                <div class="input-group-append bg-white">
                                    <span
                                        class="input-group-text border-0 font-Regular-s12 text-black border-l-radius-s12"
                                        id="basic-addon2">ريال سعودي</span>
                                </div>
                            </div>
                            <div *ngIf="price.errors && (price.dirty)" class="me-2">
                                <p *ngIf="price.errors?.['required']" class="CC-alert font-Bold-s12 m-0">يجب إدخال قيمة
                                    العرض</p>
                            </div>
                        </div>
                        <!-- Time Duration Vlaue -->
                        <div class="form-group d-flex flex-column gap-2 w-100">
                            <!-- inout name -->
                            <label for="offerValue" class="font-Bold-s16 text-dark-grey">مدة التنفيذ*</label>
                            <div class="input-group Border-light-grey bg-light-white border-f-radius-s12 w-100">
                                <!-- input -->
                                <input type="text" name="time" class="form-control border-0 p-0 bg-transparent pe-2"
                                    autocomplete="off" aria-label="offerTime" ngModel #time="ngModel" required (keypress)="this._AuthService.numberOnly($event)"
                                    aria-describedby="offerValue">
                                <!-- value -->
                                <div class="input-group-append bg-white border-l-radius-s12">
                                    <span
                                        class="input-group-text border-0 font-Regular-s12 text-black border-l-radius-s12"
                                        id="basic-addon2">آيام</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="time.errors && (time.dirty)" class="me-2">
                            <p *ngIf="time.errors?.['required']" class="CC-alert font-Bold-s12 m-0">يجب إدخال مدة
                                التنفيذ</p>
                        </div>
                    </div>
                    <!-- Advertising details -->
                    <div class="mt-4">
                        <label for="exampleFormControlTextarea1" class="form-label font-Bold-s16 text-dark-grey">تفاصيل
                            الأعلان*</label>
                        <textarea class="form-control Border-light-grey bg-light-white" id="AdvertisingDetails"
                            autocomplete="off" name="description" ngModel #description="ngModel" required
                            rows="5"></textarea>
                        <div *ngIf="description.errors && (description.dirty)" class="me-2">
                            <p *ngIf="description.errors?.['required']" class="CC-alert font-Bold-s12 m-0">يجب إدخال تفاصيل
                            الأعلان</p>
                        </div>
                    </div>
                    <div class="w-100 d-flex justify-content-end">
                        <button class="border-0 px-4 py-2 font-Bold-s16 text-white mt-4 bg-main border-f-radius-s12" [disabled]="OfferRequestForm.invalid"
                        id="submit" aria-label="submit">
                            <span *ngIf="this._AuthService.displayProggressBar">
                                <mat-spinner></mat-spinner>
                            </span>
                            <span *ngIf="!this._AuthService.displayProggressBar">نعم، إرسل العرض</span>
                        </button>
                    </div>
                    <p class="m-0 font-Regular-s14 text-dark-grey mt-4 text-center ">
                        باالنقر علي “نعم، أنشر العرض فاأنت توافق علي البنود والشروط
                        <span class="font-Bold-s14 cursor-click ">وسياسة الخصوصية والأستخدام</span>
                    </p>
                </form>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div> -->
        </div>
    </div>
</div>
