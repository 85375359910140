import { NgModule } from '@angular/core';

import { PopupChatComponent } from './popup-chat.component';
import { DateDisplayPipe } from 'src/app/core/pipes/date-display.pipe';
import {  MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [PopupChatComponent],
  imports:[CommonModule, MatFormFieldModule,FormsModule,ReactiveFormsModule, MatFormFieldModule,
    MatInputModule],
  exports: [PopupChatComponent],
  providers:[DateDisplayPipe]
})
export class PopupChatModule {}
