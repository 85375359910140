import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-actions-popup',
  templateUrl: './actions-popup.component.html',
  styleUrls: ['./actions-popup.component.scss']
})
export class ActionsPopupComponent {
 @Input()popupHeader:string=''
 @Input()popupDescription:string=''
 @Input()popupButton:string=''
 @Input()popupId:string=''
 @Input()router:string=''
}
