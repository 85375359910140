<div class="d-flex justify-content-center align-items-center" *ngIf="lat !== 0 || lng !== 0">
    <div class="p-5 border-f-radius-s12">
      <div class="d-flex flex-column align-items-center mb-4">
        <p class="m-0 font-Bold-s20 text-dark-color">يرجى تحديد موقعك علي الخريطة</p>
        <p class="m-0 font-Regular-s14 text-dark-color text-center mt-3">لمساعدتنا في تخصيص تجربتك وربطك بالفرص ذات الصلة في منطقتك، نطلب منك تثبيت موقعك على الخريطة أدناه</p>
      </div>
      <div>
        <div class="position-relative">
          <!-- search -->
          <div
            class="search-holder bg-white border-f-radius-s12 py-3 px-3 d-flex align-items-center gap-2 position-absolute box-shadow">
            <i class="ic-all-request-Vector iC-S20 z-10 d-flex cursor-click img-Background-Contain"></i>
            <input placeholder="ابحث عن مدينة ، منطقة ، حي ، شارع....." autocorrect="off" autocapitalize="off" aria-label="search"
              spellcheck="off" type="text" class="form-control" #search id="searchInput" [formControl]="searchControl" />
          </div>
          <agm-map *ngIf="!_router.url.includes('/settings')"  style="height:446px;" [zoom]='map_zoom' [latitude]="lat" [longitude]="lng" #AgmMap
            (mapClick)="mapClicked($event)">
            <agm-marker [latitude]="lati" [longitude]="lngi"></agm-marker>
          </agm-map>
          <agm-map *ngIf="_router.url.includes('/settings')"  style="height:446px;" [zoom]='map_zoom' [latitude]="lat" [longitude]="lng" #AgmMap
            (mapClick)="mapClicked($event)">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
          </agm-map>
        </div>
      </div>
      <div class="mt-4">
        <button class="bg-main py-2 w-100 font-Bold-s16 text-white border-f-radius-s12 mt-4" (click)="send()"
        id="submit" aria-label="submit">
              <span *ngIf="this._AuthService.displayProggressBar">
                <!-- <mat-spinner></mat-spinner> -->
              </span>
              <span *ngIf="!this._AuthService.displayProggressBar" class="px-md-0 px-3">
                تأكيد الموقع
              </span>
          </button>
      </div>
    </div>
  </div>
