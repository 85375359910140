<span class="example-pizza-party success border-f-radius-s15 px-2 pt-2 d-block">
    <div class="d-flex align-items-center gap-8px pb-2">
        <div class="vertical-line line-color border-f-radius-s5"></div>
        <div class="success border-f-radius-s10 p-2">
            <i class="ic-right-green iC-S20 img-Background-Contain d-flex" (click)="dismissClicked()"></i>
        </div>
        <div class="d-flex flex-column justify-content-between">
            <p class="m-0 text-style font-Bold-s16">
                {{data.title ?? message }}
            </p>
            <p class="m-0 text-support1-color font-Regular-s14 ">
                {{data?.message ?? 'تم ارسال البيانات بنجاح'}}
            </p>
        </div>
    </div>
    <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
</span>