<div class="row gx-md-5 h-100">
  <!-- form section -->
  <div class="col-md-6 ps-md-3 h-100" >
    <div class="d-flex flex-column justify-content-center bg-white border-f-radius-s20 py-4 px-lg-5 px-3 position-relative~ h-100">
      <!-- <div class="d-flex align-items-center gap-3 cursor-click mb-4" routerLink="/register/account-info"> -->
      <!-- arrow left -->
      <!-- <div
            class="bg-white-sup p-2 border-f-radius-s50 d-flex justify-content-center align-items-center cursor-click ">
            <i class="ic-arrow-small-left iC-S20 img-Background-Contain d-flex"></i>
          </div> -->
      <!-- back text -->
      <!-- <p class="m-0 font-Bold-s14 text-dark-black cursor-click">رجوع</p>
        </div> -->
      <!-- form header -->
      <div class="d-flex flex-column align-items-center gap-3 pt-2 ">
        <p class="m-0 text-dark-color font-Bold-s20">إدخال رمز التأكيد</p>
        <p class="m-0 text-light-black-color font-Regular-s14 text-center line-height-15 px-4">
          لضمان أمن حسابك، قمنا بتنفيذ طبقة إضافية من الحماية. يرجى إدخال كلمة المرور لمرة ) التي تم إرسالها إلى رقم هاتفك
          <!-- phone number -->
        </p>
      </div>
      <!-- form -->
      <!-- div holder -->
      <form class="d-flex flex-column align-items-center px-md-0 px-lg-2 mt-4">
        <!-- Verify Code -->
        <div dir="ltr" class="w-100 mb-5 d-flex justify-content-center px-md-3">
          <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
        </div>
        <p class="m-0 font-Regular-s14 text-support2-color">
          لم يصلك رمز التحقق
        </p>
        <p class="m-0 font-Bold-s14 text-dark-color">
          إعادة الإرسال رمز التأكيد
        </p>
        <!-- Horizontal line -->
        <div class="Border-b-grey-O50 horizontal-line-width mt-5"></div>
        <p class="text-small text-danger font-Regular-s14 {{dError}}">
          الرمز الذى أدخلته غير صحيح
        </p>
        <p class="m-0 font-Regular-s14 text-support2-color pt-4 mt-4"> لديك حساب بالفعل
          <span class="font-Bold-s16 text-dark-color cursor-click d-md-inline d-flex justify-content-center ">تسجيل
            الدخول</span>
        </p>
      </form>
    </div>
  </div>
  <!-- image section -->
  <div class="col-6 h-100" *ngIf="_BreakPointService.isMobile == false">
    <div class="d-flex flex-column align-items-center justify-content-center gap-3 h-100 pb-5">
      <!-- image -->
      <i class="img-account-info image-holder img-Background-Contain d-flex"></i>
      <!-- section header -->
      <p class="m-0 text-dark-color font-Bold-s32 text-center">
        اهلا بك داخل منصة
        <span class="text-main-color">شغل</span>
      </p>
      <p class="m-0 font-Regular-s14 text-light-black-color text-center mt-2 w-75">
        تهانينا على الانضمام إلى مجتمعنا النابض بالحياة من المستقلين والعملاء الموهوبين. يسعدنا وجودك على متن الطائرة،
        ونتطلع
        إلى دعمك في رحلتك المستقلة.
      </p>
    </div>
  </div>

</div>
<!-- user feedback -->