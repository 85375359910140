import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatService } from 'src/app/core/services/chat.service';

@Component({
  selector: 'app-notification-snack-bar',
  templateUrl: './notification-snack-bar.component.html',
  styleUrls: ['./notification-snack-bar.component.scss']
})
export class NotificationSnackBarComponent {
  data:any
  constructor(public _chat:ChatService){
    this._chat.currentMessage.subscribe((res:any)=> {
      this.data = res?.notification
    })
  }
  @Output() onDismissClick = new EventEmitter<any>();
  dismissClicked() {
    this.onDismissClick.emit(null);
  }

}
