import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription, combineLatest, map, of, startWith, switchMap, tap } from 'rxjs';
import { UsersService } from 'src/app/core/services/users.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { ProfileService } from 'src/app/core/services/profile.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { HttpClient } from '@angular/common/http';
import { BreakPointService } from 'src/app/core/services/break-point.service';
import {trigger,state,style,animate,transition} from '@angular/animations';
// declare var $: any;

@Component({
  selector: 'app-page-chat',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('open', style({
        width: 716,
        display: 'block' ,
        transform: 'translateX(0)',
        opacity: 1
      })),
      state('closed' , style ({
        width: 0,
        display: 'none',
        transform: 'translateX(50)',
        opacity: 0
      })),
      transition('open => closed', [
        animate('.2s')
      ]),transition('closed => open', [
        animate('.6s')
      ]),
    ])
  ]
})
export class ChatPageComponent {
  isOpen:boolean = true
  apiFile: any;
  finalFile: any;
  messageImage: any
  message: any = null;
  @ViewChild('endOfChat') endOfChat!: ElementRef;
  @ViewChild('sendInput', { static: true }) sendInput: ElementRef;
  searchControl = new FormControl('')
  messageControl = new FormControl('');
  user$ = this.usersService.currentUserProfile$;
  users$ = combineLatest([
    this.usersService.allUsers$, this.user$, this.chatService.searchControl.valueChanges.pipe(startWith(''))])
    .pipe(map(([users, user, searchString]) => 
    users.filter(u => u.displayName?.toLowerCase().includes(searchString?.toLowerCase()) && u.id !== user?.uid)))
  fileDocument: any;
  public chatCollection: AngularFirestoreCollection<any>;
  public chatSubscription: Subscription;
  location: string = "هذا العنوان غير معرف"
  public newMessageCount: number = 0;
  @ViewChild('notificationSnackBarTemplate') notificationSnackBarTemplate!: TemplateRef<any>;
  messages: any[] = [];
  userData: any;
  currentUserImage:any;
  constructor(
    private http: HttpClient,
    private profile: ProfileService,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    public _AuthService: AuthService,
    public activeRouter: Router,
    public usersService: UsersService,
    public _SnackBarService: SnackBarService,
    private router: Router,
    public _BreakPointService: BreakPointService,
    public chatService: ChatService) {
    this.chatCollection = this.firestore.collection<any>('chats');
    this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
    window.onresize = () => {
      this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
      this._BreakPointService.breakPoint.next(this._BreakPointService.isMobile)
      this._BreakPointService.mobileSizeChecker()
    };
  }
  title = 'shogol';
  userId: any;
  top: number = 0
  chatAppear:boolean = false
  city: string;
  country: string;
  right: number = 0
  bottom: number = 0
  left: number = 0
  caretIcon: string = 'ic-caret-up-icon'
  myChats$ = this.chatService.myChats$;
  selectedChat$ = combineLatest([
    this.chatService.chatListControl.valueChanges,
    this.myChats$,
  ]).pipe(map(([value, chats]) => chats.find((c) => c.id === value[0])));
  messages$ = this.chatService.chatListControl.valueChanges.pipe(
    map(value => value[0]),
    switchMap(chatId => this.chatService.getChatMessages$(chatId)),
    tap(() => {
      this.scrollToBottom();
    })
  )
  messagesUnread: Observable<any[]>
  receivedNotification: any
  handleNotification(notification: any) {
    // Do something with the notification
    this._SnackBarService.openNotificationSnackBar(this.notificationSnackBarTemplate)
    // Call your desired function here based on the notification content
    // For example: this.myFunction(notification);
    this.receivedNotification = notification?.notification;
  }
  ngOnInit(): void {
    this.currentUserImage=this.profile.Avatar.getValue()?this.profile.Avatar.getValue():JSON.parse(localStorage.getItem('user')).data.avatar;
    // this.chatService.requestPermission()
    // this.chatService.receiveMessage()
    // this._AuthService.getUserId()
  }

  // getImage(chat:any){
  //   let user=chat.users.filter((ele:any)=>ele.userName != JSON.parse(localStorage.getItem("user"))?.data?.username)[0]
  //   return user?.photoURL ?? '../../../../assets/Icons/chat/user-placeholder.svg'
  // }

  geocode(latitude: number, longitude: number) {
    const apiKey = 'YOUR_API_KEY'; // Replace with your actual API key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&language=ar&key=AIzaSyDx5CBk4DUW-nvCVnHAc-Jy0CIec0SKhR4`;
    this.http.get(url).subscribe((response: any) => {
      if (response.results && response.results.length > 0) {
        const addressComponents = response.results[0].address_components;
        for (const component of addressComponents) {
          if (component.types.includes('locality')) {
            this.city = component.long_name;
          } else if (component.types.includes('country')) {
            this.country = component.long_name;
          }
        }
      }
    });
  }
  isMessageUnread(message: any): boolean {
    return message.read
  }

  // ngOnDestroy() {
  //   this.chatSubscription.unsubscribe();
  // }
  markMessagesAsRead() {
    // Function to mark all unread messages as read.
    this.newMessageCount = 0;
  }


  fileChange(e: any) {
    // document.getElementById("input_file")!.onchange = (e: any) => {
    let filePath = e.target.value
    this.apiFile = e.target.files[0]
    let file = filePath.split('\\')
    this.finalFile = file[file.length - 1]
    // send this
    let path = filePath.split(".").splice(-1)
    this.messageImage = ''
    this.fileDocument = ''
    if (path == 'txt' || path == 'pdf' || path == 'pptx' || path == 'ppt' || path == 'xls' || path == 'xlsx' || path == 'doc' || path == 'docx' || path == 'word') {
      this.fileDocument = e.target.files[0]
    } else {
      this.messageImage = e.target.files[0]

    }


    this.messageControl.setValue(this.finalFile)
    // };
  }
  open_file() {
    document.getElementById('input_file').click();
  }
  // uploadImage() {
  //   this.storage.upload("/files" + Math.random() + this.apiFile, this.apiFile)
  // }

  createChat(user: any) {
    document.getElementById('chat')?.classList.add('d-block')
    document.getElementById('chat')?.classList.remove('d-none')
    this.chatService
      .isExistingChat(user.uid)
      .pipe(
        switchMap((chatId) => {
          if (!chatId) {
            document.getElementById('chat')?.classList.add('d-block')
            document.getElementById('chat')?.classList.remove('d-none')
            return this.chatService.createChat(user);

          } else {
            document.getElementById('chat')?.classList.add('d-block')
            document.getElementById('chat')?.classList.remove('d-none')
            return of(chatId);

          }
        })
      )
      .subscribe((chatId) => {
        this.chatService.chatListControl.setValue([chatId]);
        document.getElementById('chat')?.classList.add('d-block')
        document.getElementById('chat')?.classList.remove('d-none')
      });
  }
  sendMessage() {
    if (this.messageImage) {
      const filePath = `images/${Date.now()}_${this.messageImage.name}`;
      const task = this.storage.upload(filePath, this.messageImage);

      task
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((image) => {
          const selectedChatId = this.chatService.chatListControl.value[0];
          if (image && selectedChatId) {
            this.chatService
              .addChatMessage(selectedChatId, image)
              .subscribe(() => {
                this.scrollToBottom();
              });
            this.messageControl.setValue('');
          }
          this.messageControl.setValue('');

        })
        .catch((error) => {
          // console.error('Error uploading image: ', error);
        });
    } else if (this.fileDocument) {
      const filePath = `files/${Date.now()}_${this.fileDocument.name}`;
      const task = this.storage.upload(filePath, this.fileDocument);

      task
        .then((snapshot) => snapshot.ref.getDownloadURL())
        .then((url) => {
          const selectedChatId = this.chatService.chatListControl.value[0];
          if (url && selectedChatId) {
            this.chatService
              .addChatMessage(selectedChatId, url)
              .subscribe(() => {
                this.scrollToBottom();
              });
            this.messageControl.setValue('');
          }
          this.messageControl.setValue('');

        })
        .catch((error) => {
          // console.error('Error uploading file: ', error);
        });
    } else {
      const message = this.messageControl.value;
      const selectedChatId = this.chatService.chatListControl.value[0];
      if (message && selectedChatId) {
        this.chatService
          .addChatMessage(selectedChatId, message)
          .subscribe(() => {
            this.scrollToBottom();
          });
        this.messageControl.setValue('');
      }
    }
    this.messageControl.setValue('');
    this.messageImage = ''
    this.fileDocument = ''
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.endOfChat) {
        this.endOfChat.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }

  close() {
    this.chatAppear = false
    this.isOpen = false
  }
  userProfile(name: any) {
    this.chatService.getUserDataFromChat(name).subscribe((res: any) => {
      this.userId = res.data.data.id
        this.router.navigate(['/profile', this.userId]);
    })

  }

  cardChat(name: any) {
    this.chatAppear = true
    this.chatService.getUserDataFromChat(name).subscribe((res: any) => {
      this.userData = res.data.data
        if (res?.data?.complete_profile - 25 >= 0) {
          this.top = 100
          let right = res?.data?.complete_profile - 25
          if (right - 25 >= 0) {
            this.right = 100
            let bottom = right - 25
            if (bottom - 25 >= 0) {
              this.bottom = 100
              let left = bottom - 25
              if (left - 25 == 0) {
                this.left = 100
              } else {
                this.left = left * 4
              }
            } else {
              this.bottom = bottom * 4
            }
          } else {
            this.right = right * 4
          }
        } else {
          this.top = res?.data?.complete_profile * 4
        }
        if (res.data.data?.lat !== null && res.data.data?.lan !== null) {
          this.geocode(res.data.data.lat, res.data.data.lan)
        }
    })
  }
  
  getChatImg(img:any){
    return typeof(img) !="object" && img? img : '../../../../assets/Icons/chat/user-placeholder.svg'
  }
}
