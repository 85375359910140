import { HttpClient } from '@angular/common/http';
import { Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  location:any = ''
  type_work:any = ''
  category:any = []
  price:any = ''
  compnay:any = ''
  freelancer:any = ''

  constructor(private http: HttpClient) {
  }
  // get categories from api
  getCategories(){
    return this.http.get(`${environment.apiUrl}/api/coredata/category/list`)
  }
  // search for categories from api
  searchCategories(name:any){
    return this.http.get(`${environment.apiUrl}/api/coredata/category/list?name=${name}`)
  }

  // get data for tlbat from api
  getData(){
    return this.http.get(`${environment.apiUrl}/api/task/list?perPage=20&pagination=true`)
  }

  PaginationOfData(numberOfPag:number){
    return this.http.get(`${environment.apiUrl}/api/task/list?perPage=20&pagination=true&page=${numberOfPag}`)
  }
  // search data for tlbat from api
  // search data for tlbat from api
  searchData(){
    return this.http.get(`${environment.apiUrl}/api/task/list?price=${this.price}&location=${this.location}&type_work=${this.type_work}&category=${this.category}&compnay=${this.compnay}&freelancer=${this.freelancer}`)
  }
}
