import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { BreakPointService } from 'src/app/core/services/break-point.service';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { ChatService } from 'src/app/core/services/chat.service';
import { DataSaveService } from 'src/app/core/services/data-save.service';
import { RequestService } from 'src/app/core/services/request.service';
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { UsersService } from 'src/app/core/services/users.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';

declare var $: any;
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        overflow: 'hidden',
      })),
      state('closed', style({
        overflow: 'hidden',
        height: '0px',
      })),
      transition('open => closed', [
        animate('.2s')
      ]),
      transition('closed => open', [
        animate('.2s')
      ]),
    ]),
  ],
})
export class CategoriesComponent {
  isOpen: boolean = true
  category: any[] = []
  chevron: string = 'chevron_left'
  categoryId: any[] = []
  data: any[] = []
  errorMessage:any;
  roleIdErrorMessage: any;
  emailErrorMessage: any;
  fullnameErrorMessage: any;
  mobileErrorMessage: any;
  passwordErrorMessage: any;
  userNameErrorMessage: any;
  constructor(public _toasterService: ToasterService,private _chat:ChatService , public snackBar: MatSnackBar,private _categories: CategoriesService, private _data: DataSaveService, private router: Router, public _AuthService: AuthService, public usersService: UsersService
    , public _BreakPointService: BreakPointService) {
    this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
    window.onresize = () => {
      this._BreakPointService.isMobile = this._BreakPointService.getIsMobile();
      this._BreakPointService.breakPoint.next(this._BreakPointService.isMobile)
      this._BreakPointService.mobileSizeChecker()
    };
    this._categories.getCategories().subscribe((res: any) => {
      this.dataSource.data = res.data;
    })
  }
  openTree() {
    if (this.isOpen == false) {
      this.isOpen = true
      this.chevron = ' expand_more '
    } else if (this.isOpen == true) {
      this.isOpen = false
      this.chevron = 'chevron_left'
    }
  }
  getLevel = (node: any) => node.level;
  private _transformer = (node: any, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      par_id: node.parent_id,
      checked: false
    };
  };
  treeControl = new FlatTreeControl<any>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );
  dataSource: any = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: any, node: any) => node.expandable;
  checklistSelection = new SelectionModel<any>(true);
  descendantsAllSelected(node: any): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (this.checklistSelection.selected.length > 0) {
      // (<HTMLInputElement>document.getElementById("categoriesBtn")!).disabled = false
    } else if (this.checklistSelection.selected.length == 0) {
      // (<HTMLInputElement>document.getElementById("categoriesBtn")!).disabled = true
    }
    return descAllSelected;
  }
  descendantsPartiallySelected(node: any): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }
  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: any): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node)
    this.data = (this.checklistSelection.selected.map((a: any) => a))

  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: any): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.data = (this.checklistSelection.selected.map((a: any) => a))


  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: any): void {
    let parent: any | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }
  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: any): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }
  getParentNode(node: any): any | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }
  // search for categories
  searchForCategories(event: any) {
    this._categories.searchCategories(event.value).subscribe((res: any) => {
      this.dataSource.data = res.data
    })
  }
  remove(event: any) {
    if (event.parent_id == 0) {
      this.todoItemSelectionToggle(event)
    }
    if (event.parent_id !== 0) {
      this.todoLeafItemSelectionToggle(event)
    }
  }
  Category() {
    $('#Category').modal('show');
  }
  saveData() {
    let id = this.checklistSelection.selected.map((a: any) => a.id)
    this._AuthService.registerationForm = {
      ...this._AuthService.registerationForm,
      category: id,
      device_token:this._chat.token
    }
    this._AuthService.displayProggressBar = true;
    this._AuthService.register(this._AuthService.registerationForm).subscribe((res: any) => {
        this._AuthService.uplodAvater(res.data.id , this._AuthService.avater).subscribe((res: any) => {
            const { email, password, username, avatar } = this._AuthService.registerationForm;
            this._AuthService
            .signUp(email, password)
            .pipe(
              switchMap(({ user: { uid } }) =>
              this.usersService.addUser({ uid, email, displayName: username, photoURL: res?.data?.avatar })
              )
              ).subscribe(() => {
                this._AuthService.displayProggressBar = false;
                this.router.navigate(['/register/welcome']);
              },err => {
                this._AuthService.displayProggressBar = false;
                this.emailErrorMessage = err.error.message.email
                this._toasterService.showError(
                  this.roleIdErrorMessage?this.roleIdErrorMessage:'' + this.emailErrorMessage? this.emailErrorMessage :'' +
                  this.fullnameErrorMessage?this.fullnameErrorMessage:'' + this.mobileErrorMessage?this.mobileErrorMessage:'' + this.passwordErrorMessage?this.passwordErrorMessage:'' +
                  this.userNameErrorMessage
                );
              });
        })
    },err =>{
      this.roleIdErrorMessage = err.error.message?.role_id
      this.emailErrorMessage = err.error.message.email
      this.fullnameErrorMessage = err.error.message.fullname
      this.mobileErrorMessage = err.error.message.mobile
      this.passwordErrorMessage = err.error.message.password
      this.userNameErrorMessage = err.error.message.username
      this._toasterService.showError(
        this.roleIdErrorMessage?this.roleIdErrorMessage:'' + this.emailErrorMessage? this.emailErrorMessage :'' +
        this.fullnameErrorMessage?this.fullnameErrorMessage:'' + this.mobileErrorMessage?this.mobileErrorMessage:'' + this.passwordErrorMessage?this.passwordErrorMessage:'' +
        this.userNameErrorMessage
      );
      this._AuthService.displayProggressBar = false;
    })
  }
  clearAll() {
    this.checklistSelection.deselect(...this.data)
    this.data = []
    this.checklistSelection.selected.map((a: any) => a.checked = false);

    (<HTMLInputElement>document.getElementById("categoriesBtn")!).disabled = true
  }
}
