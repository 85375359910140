<!-- splide of images and videos -->
<div class="splide" role="group" aria-label="Splide Basic HTML Example" dir="ltr">
    <div class="splide__track">
        <ul class="splide__list">
                <li *ngFor="let item of documentImage"  class="splide__slide"><img class="cursor-click" [src]="item.file" [id]="item.file" (click)="openPopup(item.file)"></li>
                <li  *ngFor="let item of documentVideo ; let i = index"  class="splide__slide"><video controls ><source  [src]="item.file" type = "video/mp4" ></video></li>
        </ul>
    </div>
</div>
<!-- pdf -->
<ng-container>
    <div class="d-flex align-items-center gap-3 mb-3" *ngFor="let attachment of this.documentPdf">
      <div class="bg-white-sup border-f-radius-s12 p-2">
        <i class="{{attachment.split('.').splice(-1) =='pdf' ? 'ic-request-details-Pdf' : attachment.split('.').splice(-1) =='word' ? 'ic-request-details-Docs' : attachment.split('.').splice(-1) =='xls' ? 'ic-request-details-Xls' :'ic-request-details-other-documents'}}
        iC-S24 img-Background-Contain d-flex"></i>
      </div>
      <a href="{{attachment}}" target="_blank" aria-label="attachment"
        class="m-0 font-Regular-s16 text-support2-color">{{attachment.split("https://shogol.sa/Admin/public/uploads/task")}}</a>
    </div>
  </ng-container>
  <!-- Modal rate for freelancer -->
<div class="modal fade" id="imagePopup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content border-0 border-f-radius-s12">
        <!-- Modal content -->
        <div class="modal-body p-5">
            <img [src]="imagesPopup" class="w-100 h-100">
        </div>
      </div>
    </div>
  </div>