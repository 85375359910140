import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-warning-snack-bar',
  templateUrl: './warning-snack-bar.component.html',
  styleUrls: ['./warning-snack-bar.component.scss']
})
export class WarningSnackBarComponent {
  @Input() message: any;
  @Output() onDismissClick = new EventEmitter<any>();
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<WarningSnackBarComponent>
  ) {}
    dismissClicked() {
    this.onDismissClick.emit(null);
  }
  ngOnInit(): void {}
}
