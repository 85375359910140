import { BehaviorSubject, Observable, Subscription, fromEvent, observable, of } from 'rxjs';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ProfileService } from 'src/app/core/services/profile.service';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  map
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  @Input() open:boolean
  @Input() data:any ;
  constructor(private profile:ProfileService ,  private http:HttpClient){
  }
read(id:number){
  this.profile.getRedNotification(id).subscribe((res:any)=> {
    window.location.reload()
  })
}
}
