<div class="bg-white border-f-radius-s12 Border-f-purple-25 pt-3 relative" >
  <p class="font-Bold-s16 text-dark-color px-3 m-0">الإشعارات</p>
  <div *ngFor="let item of data;let i = index"
  [ngClass]="{'bg-white-sup' : item?.read == null,
              'border-f-radius-s12' : i == 4}"
  class="Border-B-grey px-3 cursor-click"
  [routerLink]='["/request/request-details" , item?.notifiable_id]'>
    <div class="d-flex flex-column pb-2 pt-2" (click)="read(item.id)">
      <p class="m-0 text-dark-black font-Bold-s14">{{item.title}}</p>
      <p class="m-0 mt-2 text-dark-black font-Regular-s12">{{item.description}}</p>
      <p class="m-0 d-flex text-support2-color font-Regular-s10 justify-end mt-2">
        <i class="d-flex mx-2 img-Background-Cover iC-S16 ic-all-request-time"></i>
          قبل{{item.created_at}}
      </p>
    </div>
  </div>
  <div *ngIf="data?.length == 0" class="w-100 justify-content-center align-items-center d-flex flex-column p-3" style="height: calc(100% - 80px);">
    <i class="ic-profile-emty-notifications d-flex mx-2 img-Background-Cover iC-S-notifications m-5"></i>
    <p class="text-dark-black font-Bold-s20 mt-2 m-0">لاتوجد نتائج إشعارات</p>
  </div>
</div>
