import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent {
  skeleton:any = [{},{},{},{},{}]
  @Input() open:boolean =false
  @Input() Margen:string = ''
}
