import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';



declare var $;

@Component({
  selector: 'app-textarea-summernote',
  templateUrl: './textarea-summernote.component.html',
  styleUrls: ['./textarea-summernote.component.scss']
})
export class TextareaSummernoteComponent implements OnChanges {
  public editorDisabled = false;
  @Input() dataValue:any;
  @Output() send =  new EventEmitter<any>();
  @Output() required =  new EventEmitter<any>();
  formDescription!: FormGroup 
  public config: any = {
    airMode: false,
    tabDisable: true,
    popover: {
      table: [
        ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
        ['delete', ['deleteRow', 'deleteCol', 'deleteTable']]
      ],
      image: [
        ['image', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']]
      ],
      link: [['link', ['linkDialogShow', 'unlink']]],
      air: [
        [
          'font',
          [
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'superscript',
            'subscript',
            'clear'
          ]
        ]
      ]
    },
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo', 'codeBlock']],
      [
        'font',
        [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          'clear'
        ]
      ],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
      ['customButtons', ['testBtn']],
      ['view', ['fullscreen', 'codeview', 'help']]
    ],
    fontSizes: ['8','9','10','11','12','14','18','24','36','44','56','64','76','84','96'],
    fontNames: ['Arial', 'Times New Roman','Inter', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times', 'MangCau', 'BayBuomHep','BaiSau','BaiHoc','CoDien','BucThu', 'KeChuyen', 'MayChu', 'ThoiDai', 'ThuPhap-Ivy', 'ThuPhap-ThienAn'],
    codeviewFilter: true,
    codeviewFilterRegex: /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true
  };

  get sanitizedHtml() {
    return this.sanitizer.bypassSecurityTrustHtml(this.formDescription.get('html').value);
  }

  get f() {
    return this.formDescription.controls;
  }
  get info() {
    return this.formDescription.get('info')!;
  }
  description: any;
  constructor(private sanitizer: DomSanitizer , formBuilder: FormBuilder) {
    this.description = {} as any; 
    this.formDescription = formBuilder.group({
      info: new FormControl(this.description.info, [
        Validators.required,
        Validators.minLength(30),
        Validators.maxLength(5000),
      ])
    });    
  }
  ngOnChanges(changes: SimpleChanges) {}


  public onBlur(event) {
    this.send.emit(this.dataValue)
    this.required.emit(this.info.errors)
  }

  public onDelete(file) {
  }

  public summernoteInit(event) {
  }

}



