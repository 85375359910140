import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[appDebounceInput]'
})
export class DebounceInputDirective implements OnDestroy {
  @Input() debounceTime = 500;
  @Output() debounceInput = new EventEmitter();

  private inputSubject = new Subject<any>();

  constructor() {
    this.inputSubject.pipe(debounceTime(this.debounceTime)).subscribe((value) => {
      this.debounceInput.emit(value);
    });
  }

  @HostListener('keyup', ['$event'])
  onKeyup(event: any) {
    this.inputSubject.next(event);
  }

  ngOnDestroy() {
    this.inputSubject.unsubscribe();
  }
}
