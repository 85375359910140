<div class="row gx-lg-5 h-100">
  <!-- form section -->
  <div class="col-lg-6 ps-lg-3">
    <div class="bg-white border-f-radius-s20 py-lg-4 py-2 px-lg-5 px-3  ">
      <div class="d-flex align-items-center gap-3 cursor-click mb-1" routerLink="/register/location-info">
        <!-- arrow left -->
        <div
          class="bg-white-sup p-2 border-f-radius-s50 d-flex justify-content-center align-items-center cursor-click ">
          <i class="ic-arrow-small-left iC-S20 img-Background-Contain d-flex"></i>
        </div>
        <!-- back text -->
        <p class="m-0 font-Bold-s14 text-dark-black cursor-click">رجوع</p>
      </div>
      <!-- form header -->
      <div class="d-flex flex-column align-items-center gap-3">
        <p class="m-0 text-dark-color font-Bold-s20">معلومات الشركة</p>
        <p class="m-0 text-light-black-color font-Regular-s14 text-center line-height-15 px-2">
          مرحبا بك في منصة شغل، حيث تعد هويتك الفريدة وخبرتك المهنية ضرورية للتواصل مع العملاء وعرض مهاراتك. للبدء،
          ستحتاج إلى
          تقديم بعض المعلومات الرئيسية، بما في ذلك رقم هويتك والمسمى الوظيفي.
        </p>
      </div>
      <!-- form -->
      <form class="mt-4" #companyInfo='ngForm' autocomplete="off" (ngSubmit)="onSubmit(companyInfo.value)">
        <!-- user image -->
        <div class="d-flex align-items-center justify-content-between gap-3">
          <input type='file' id="imageUpload" name="avatar" ngModel accept=".png, .jpg, .jpeg" #fileInput
            (change)="uploadFile($event)" aria-label="avatar" />
          <div id="imagePreview" [style.backgroundImage]="'url('+ imageUrl +')'"
            class="flex-shrink-0 img-Background-Cover p-4 bg-white-sup Border-dashed border-f-radius-s12 d-flex justify-content-center align-items-center">
            <i class="cursor-click ic-camera iC-S20 img-Background-Contain" (click)="fileInput.click()"></i>
          </div>
          <div class="d-flex flex-column gap-2 h-100 align-items-between justify-content-between">
            <p class="m-0 font-Bold-s16 text-dark-color">لوجو الشركة</p>
            <p class="m-0 text-light-black-color font-Regular-s14 line-height-15">
              قم بتحميل شعار شركتك إلى منصة شغل،قم بتمكين صعود علامتك التجارية إلى آفاق جديدة في الأفق المستقل.
            </p>
          </div>
        </div>
        <!-- job name -->
        <div class="d-flex flex-column gap-2 w-100 mt-3">
          <p class="m-0 font-Bold-s14 text-dark-color">
            تخصص الشركة
          </p>
          <input type="text" name="job_name" autocomplete="none" aria-label="job_name"
            class="form-control Border-light-grey bg-white border-f-radius-s12 h-100 px-3 py-2 font-Regular-s14 text-support2-color"
            id="job_name" aria-describedby="nameHelp" ngModel #job_name="ngModel"
            placeholder="Senior UX-UI Designer" minlength="8" maxlength="50">
          <div *ngIf="job_name.errors && (job_name.dirty || job_name.touched)">
            <p *ngIf="job_name.errors?.['minlength']" class="m-0 CC-alert"> تخصص الشركة يجب أن لا يقل عن 8 حروف والارقام يجب ادخالها بالانجليزية
            </p>
            <p *ngIf="job_name.errors?.['maxlength']" class="m-0 CC-alert">
              تخصص الشركة يجب أن لا يقل عن 50 حروف
            </p>
          </div>
        </div>
        <!-- about -->
        <div class="d-flex flex-column gap-2 w-100 mt-3">
          <p class="m-0 font-Bold-s14 text-dark-color">
            نبذة عن الشركة
          </p>
          <div class="w-100 relative">
            <textarea
              placeholder="هل أنت مستقل موهوب تبحث عن مشاريع وفرص جديدة لعرض مهاراتك؟ لا مزيد من البحث! انضم إلى مجتمعنا المستقل النابض بالحياة وافتح الباب أمام عالم من الوظائف والتعاون المستقل المثير. هل أنت مستقل موهوب تبحث عن مشاريع وفرص جديدة لعرض مهاراتك؟ لا مزيد من البحث! انضم إلى مجتمعنا "
              class="form-control Border-light-grey bg-white border-f-radius-s12 h-100 px-3 py-2 font-Regular-s14 text-support2-color"
              name="info" cols="30" rows="8" ngModel #info="ngModel" id="info" maxlength="5000" minlength="30" required
              (ngModelChange)="myChange($event)"></textarea>
            <span
              class="position-absolute bottom-4 text-support2-color font-Regular-s16 left-4">{{remainingText}}</span>
          </div>
          <div *ngIf="info.errors && (info.dirty || info.touched)">
            <p *ngIf="info.errors?.['minlength']" class="m-0 CC-alert"> نبذة عن الشركة يجب أن لا يقل عن 30 حرف
            </p>
            <p *ngIf="info.errors?.['maxlength']" class="m-0 CC-alert">
              نبذة عن الشركة يجب أن لا تزيد عن 5000 حرف
            </p>
          </div>
        </div>
        <!-- nationaltiy number  -->
        <div class="d-flex flex-column gap-2 w-100 mt-3 mb-2">
          <p class="m-0 font-Bold-s14 text-dark-color">
            السجل التجاري
          </p>
          <input type="text" name="commercial_number" autocomplete="none" aria-label="commercial_number"
            class="form-control Border-light-grey  bg-white border-f-radius-s12 h-100 px-3 py-2 font-Regular-s14 text-support2-color"
            id="commercial_number" aria-describedby="nameHelp" ngModel #commercial_number="ngModel" placeholder="62832923092"
            minlength="4" maxlength="30">
          <div *ngIf="commercial_number.errors && (commercial_number.dirty || commercial_number.touched)">
            <p *ngIf="commercial_number.errors?.['minlength']" class="m-0 CC-alert"> السجل التجاري يجب أن لا يقل عن 4 حروف
            </p>
            <p *ngIf="commercial_number.errors?.['maxlength']" class="m-0 CC-alert">
              السجل التجاري يجب أن لا يزيد عن 30 حروف
            </p>
          </div>
        </div>
        <!-- next -->
        <button type="submit" [disabled]="companyInfo.invalid || !imageSelected" id="submit" aria-label="submit"
          class="w-100 border-f-radius-s12 border-0 btn-holder py-3 px-5 bg-main text-white font-Bold-s16 mt-3">
          <span *ngIf="this._AuthService.displayProggressBar">
            <mat-spinner></mat-spinner>
          </span>
          <span *ngIf="!this._AuthService.displayProggressBar" class="px-lg-0 px-3">التالي</span>
        </button>
      </form>
    </div>
  </div>
  <!-- image section -->
  <div class="col-6 h-100" *ngIf="_BreakPointService.isMobile == false">
    <div class="d-flex flex-column align-items-center justify-content-center gap-3 h-100 pb-5">
      <!-- image -->
      <i class="img-account-info image-holder img-Background-Contain d-flex"></i>
      <!-- section header -->
      <p class="m-0 text-dark-color font-Bold-s32 text-center">
        اهلا بك داخل منصة
        <span class="text-main-color">شغل</span>
      </p>
      <p class="m-0 font-Regular-s14 text-light-black-color text-center mt-2 w-75">
        تهانينا على الانضمام إلى مجتمعنا النابض بالحياة من المستقلين والعملاء الموهوبين. يسعدنا وجودك على متن الطائرة،
        ونتطلع
        إلى دعمك في رحلتك المستقلة.
      </p>
    </div>
  </div>

</div>
