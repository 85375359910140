<!-- Request Offer Status -->
<div class="bg-white border-f-radius-s12 py-4 my-3">
    <div class="d-flex align-items-center gap-3 pe-2">
        <!-- Vertical Line -->
        <div class="Vertical-Line bg-yellow border-f-radius-s12 ms-2"></div>
        <!-- status icon -->
        <div class="bg-yellow iC-S40 border-f-radius-s12"></div>
        <!-- Status text -->
        <div class="d-flex flex-column gap-1">
            <p class="m-0 font-Bold-s16 text-dark-black">طلبك قيد المراجعة من مقدم الطلب</p>
            <p class="m-0 font-Regular-s16 text-dark-black">نشكرك على تقديم طلبك على منصتنا. نحن نقدر اهتمامك بنشر مشروع
                والتواصل مع المستقلين الموهوبين.</p>
        </div>
    </div>
</div>