<form [formGroup]="formDescription" #form="ngForm"
  style="height: 360px;">
    <!-- <textarea name="description" id="description"
    required #description="ngModel" [(ngModel)]="dataValue">
    </textarea> -->
  <textarea class=" w-100 font-Regular-s16 py-2.5 px-4 bg-light-white text-support2-color border-f-radius-s15 border-1 Border-light-grey"  
    formControlName="info" 
    [ngxSummernote]="config"
    (blur)="onBlur($event)"
    name="info" id="info"
    (summernoteInit)="summernoteInit($event)"
    [(ngModel)]="dataValue" required minlength="10" maxlength="5000"
    >
  </textarea>
    <!-- <small *ngIf="form.get('html').hasError('required')">Required!</small> -->
</form>
<div class="mt-4" *ngIf="info.invalid && (info.dirty || info.touched)">
  <p *ngIf="info.errors?.['minlength']" class="m-0 CC-alert">{{'تفاصيل الأعلان يجب أن لا يقل عن 30 حروف' | translate}}</p>
</div>