<!-- chats div -->
<div class="l_c_h d-md-block d-none~ box-F-shadow pop-up-chat-page" dir="rtl"
  *ngIf='!this.activeRouter.url.includes("/register") && !this.activeRouter.url.includes("/login") && _AuthService.userValue?.status && !this.activeRouter.url.includes("/forget-password") && !this.activeRouter.url.includes("/chat")'>
  <div class="c_h" (click)="openChatsPopUp()">
    <!-- chat header -->
    <div class="left_c d-flex justify-content-between align-items-center w-100 px-3 py-2">
      <div class="d-flex align-items-center gap-2">
        <i class="ic-chat-icon iC-S20 d-flex img-Background-Cover"></i>
        <p class="m-0 font-Regular-s16 text-white">الرسائل</p>
      </div>
      <div class="left right_c left_icons">
        <a href="#" class="mini" style="font-size:23px;" aria-label="home">
          <i class="ic-caret-up-icon iC-S24 d-flex img-Background-Cover arrow-direction"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="chat_container" style="display: none;height:542px;">

    <div class="search-input mt-4">
      <!-- <mat-form-field> -->
      <div class="d-flex align-items-center gap-2 w-100 Border-light-grey border-f-radius-s12 bg-light-blue py-2 px-3">
        <i class="ic-all-request-Vector iC-S16 z-10 d-flex cursor-click img-Background-Cover"></i>
        <input matInput placeholder="أبحث عن ..." [formControl]="searchControl" [matAutocomplete]="users"
          class="bg-light-blue w-100" aria-label="search">
      </div>
      <!-- </mat-form-field> -->
      <!-- Dropdown menu -->
      <mat-autocomplete #users="matAutocomplete">
        <mat-option *ngFor="let user of users$|async" (click)="openComponentChatPopUp($event,user)" [value]="''">{{user?.displayName}}</mat-option>
      </mat-autocomplete>
    </div>
    <mat-selection-list [multiple]="false" [formControl]="chatListControl"  *ngIf="user$ | async as currentUser" class="mian-chats-list">
      <mat-list-option *ngFor="let chat of myChats$ | async" [value]="chat.id" class="mb-3" [class.new-message-container]="chat?.haveUnseenMessages && currentUser.uid !==chat?.lastMsgsenderId">
        <div class="d-flex align-items-center gap-2" (click)="openComponentChatPopUp($event,chat)"
        [class.new-message]="chat?.haveUnseenMessages && currentUser.uid !==chat?.lastMsgsenderId">
          <img class="iC-S40 border-f-radius-s50" matListAvatar loading="lazy" alt="chatPic" [src]="getChatImg(chat.chatPic)" />
          <div class="d-flex flex-column w-100">
            <p mat-line class="chat-title m-0 gap-2 w-100">
              <span class="chat-name text-bolder font-Bold-s14 text-dark-color">{{ chat.chatName }}</span>
              <span>
                <span class="chat-date font-Regular-s12 text-support2-color">{{chat.lastMessageDate | dateDisplay}}</span>
                <span [class.new-message-sign]="chat?.haveUnseenMessages && currentUser?.uid !==chat?.lastMsgsenderId"></span>
              </span>
            </p>
            <small class="font-Regular-s12 text-support2-color" mat-line>{{ chat.lastMessage ? chat.lastMessage :
              'making new message' }}</small>
          </div>
        </div>

        <!-- horizontal line -->
        <div class="horizontal-line"></div>
      </mat-list-option>
    </mat-selection-list>
  </div>
</div>


