import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-snack-bar',
  templateUrl: './error-snack-bar.component.html',
  styleUrls: ['./error-snack-bar.component.scss']
})
export class ErrorSnackBarComponent {
  @Input() message: string;
  @Output() onDismissClick = new EventEmitter<any>();
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<ErrorSnackBarComponent>
  ) {}
  
  dismissClicked() {
    this.onDismissClick.emit(null);
  }
}
