import { NgModule } from '@angular/core';
import { AsyncPipe, CommonModule, DatePipe } from '@angular/common';
import { regesterationRoutingModule } from './regesteration-routing.module';
import { RegesterationComponent } from './regesteration.component';
import { AccountTypeComponent } from './pages/account-type/account-type.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountInfoComponent } from './pages/account-info/account-info.component';
import { IntlInputPhoneModule } from 'intl-input-phone';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OtpVerificationComponent } from './pages/otp-verification/otp-verification.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { LocationMapComponent } from './pages/location-map/location-map.component';
import { AgmCoreModule } from '@agm/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { FreelancerTypeComponent } from './pages/freelancer-type/freelancer-type.component';
import { FreelancerInfoComponent } from './pages/freelancer-info/freelancer-info.component';
import { CompanyInfoComponent } from './pages/company-info/company-info.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { environment } from 'src/environments/environment';
@NgModule({
  declarations: [
   RegesterationComponent,
   AccountTypeComponent,
   AccountInfoComponent,
   OtpVerificationComponent,
   LocationMapComponent,
   FreelancerTypeComponent,
   FreelancerInfoComponent,
   CompanyInfoComponent,
   WelcomeComponent,
   CategoriesComponent
  ],
  imports: [
    CommonModule,
    regesterationRoutingModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    IntlInputPhoneModule,
    MatProgressSpinnerModule,
    NgOtpInputModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapKey,
      language: 'ar',
      libraries: ['places', 'geometry']
    }),
    GoogleMapsModule,
    SharedModule,
    MatTreeModule,
    MatIconModule,
    MatSnackBarModule
    
  ],
  exports: [
    RegesterationComponent,
    AccountTypeComponent,
    AccountInfoComponent,
    OtpVerificationComponent,
    LocationMapComponent,
    FreelancerTypeComponent,
    FreelancerInfoComponent,
    CompanyInfoComponent,
    WelcomeComponent,
    CategoriesComponent
  ],
  providers: [AsyncPipe, DatePipe]

})
export class RegesterationModule { }
